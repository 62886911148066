(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  var carousel = $(".carousel");

  if (carousel.size()) {
    var hammertime = new Hammer(carousel.get(0));
    hammertime.on('swipeleft', function (ev) {
      carousel.carousel('next');
    });
    hammertime.on('swiperight', function (ev) {
      carousel.carousel('prev');
    });
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],2:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {
  var loading = App.element.data('loading');

  /**
   * Get search query string
   * @returns {string}
   */
  function getQuery() {
    var query = document.location.search + '',
        queryString = query.substr(1),
        queryParts = queryString.split('&'),
        searchTerm = '';

    $.each(queryParts, function (i, param) {
      var match = param.match(/^s=(.*)/);
      if (match && match.length) {
        searchTerm = match[1];
      }
    });
    return searchTerm;
  }

  /**
   * Get page number if present
   * @returns {string}
   */
  function getPage() {
    var page = document.location.search.match(/page=(\d+)/);
    if (page && page.length) {
      return page[1] + '';
    }
    return "";
  }

  /**
   * Get combined string with query and page, if query present
   * Returns current title if not a query.
   *
   * @returns {string}
   */
  function queryTitle() {
    if (!getQuery().length) {
      return document.title + '';
    }
    return getQuery();
  }

  /**
   * Upon page load check if query is present and create history entry.
   */
  if (getQuery().length) {
    document.title = queryTitle();
  }

  /*
   * Convert Query String to Array
   */
  var parseQueryString = function parseQueryString() {

    var str = window.location.search;
    var objURL = {};

    str.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"), function ($0, $1, $2, $3) {
      objURL[$1] = $3;
    });
    return objURL;
  };

  /**
   * Filter
   */
  var productBlocks = $('.product-blocks'),
      filterRequest = null,
      filterOpen = $.cookie('filter');

  if (typeof filterOpen === 'string') {
    filterOpen = filterOpen.split(',');
  }
  if (!$.isArray(filterOpen)) {
    filterOpen = [];
  }

  function filterGroupId(el) {
    var panel = $(el);
    return panel.find('> .panel-heading').attr('id');
  }

  function filterGroupShow(el) {
    var panel = $(el),
        id = filterGroupId(el);
    panel.find('> .panel-body').removeAttr('style').collapse('show');
    panel.removeClass('group-closed').addClass('group-open');
    if (id !== 'filterpanel') {
      filterOpen.push(id);
    }
  }

  function filterGroupHide(el) {
    var panel = $(el),
        id = filterGroupId(el);
    panel.find('> .panel-body').collapse('hide');
    panel.removeClass('group-open').addClass('group-closed');
    if (id !== 'filterpanel') {
      filterOpen = _(filterOpen).without(id);
    }
  }

  // collapse filters on load, if mobile width
  function filterInit() {

    if ($(window).width() > 753) {
      // open whats stored in cookie
      productBlocks.find('.filter-groups > .group-closed').each(function () {
        if (_(filterOpen).contains(filterGroupId(this))) {
          // open, but don't animate
          $(this).removeClass('group-closed').addClass('group-open');
          $(this).find('> .panel-body').addClass('in');
        }
      });

      // open outer and main-filter group
      filterGroupShow(productBlocks.find('.filter-panel-outer'));
      filterGroupShow(productBlocks.find('.group-categories'));

      // force "in" class for immediate display (for responsive transition)
      productBlocks.find('.filter-panel-outer > .panel-body').addClass('in');
    } else {
      // mobile, hide everthing
      productBlocks.find('.group-open').each(function () {
        filterGroupHide(this);
      });
    }

    // init infinite scrolling plugin
    if (!$('#productlist_items').hasClass('infinite-intalized')) {
      $('#productlist_items .infinite-scroll').jscroll({
        loadingHtml: '<img src="' + loading + '" alt="Loading ...">',
        padding: -200,
        nextSelector: 'a.infinite-next:last',
        contentSelector: '.result-set'
      });
      $('#productlist_items').addClass('infinite-intalized');
    }

    var noFilters = !$.trim($('#productlist_filters').html());
    $('#productlist_sidebar').find('.filter-reset').toggleClass('hidden', noFilters);
  }

  // build query url from current location string and form
  function filterQueryUrl() {
    var search = parseQueryString(),
        isSearch = !(_.isUndefined(search['t']) && _.isUndefined(search['s'])),
        isOrdered = !_.isUndefined(search['o']),
        form = productBlocks.find('.filter-form'),
        query,
        queryUrl,
        queryParts = [];

    if (isSearch) {
      queryParts.push('t=' + search['t'] + '&s=' + search['s']);
    }

    if (isOrdered) {
      queryParts.push('o=' + search['o']);
    }

    // gather form input params and filter empty fields (select options)
    queryParts.push(decodeURIComponent($.param(_(form.serializeArray()).filter(function (p) {
      p.value += ''; // cast to string
      return p.value.length > 0;
    }))));

    // build query string for URL
    query = queryParts.join('&') + '';
    queryUrl = document.location.pathname + (query.length ? '?' + query : '');

    // remove any trailing ampersand
    return queryUrl.substr(-1) === '&' ? queryUrl.substr(0, queryUrl.length - 1) : queryUrl;
  }

  // send input/select data to server, show loading and replace productblocks html
  function filterSubmit() {
    var url = filterQueryUrl();

    // update address bar
    if (typeof window.history.replaceState === 'function') {
      window.history.replaceState(null, null, url);
    }

    // add loading icon
    var loadingHTML = '<div class="filter-loading"><img src="' + loading + '" alt="Loading ..."></div>';
    $('#productlist_items > .filter-loading').remove();
    $('#productlist_items').prepend(loadingHTML);
    $('#filters_no_results').hide();

    // load from server
    if (filterRequest && typeof filterRequest.abort === 'function') {
      filterRequest.abort(); // cancel any pending to avoid events overlapping
    }
    filterRequest = $.get(url, function (html, status, xhr) {
      var resultHtml = $(html),
          numResults = xhr.getResponseHeader('X-Num-Results');
      if (numResults > 0) {
        $('#productlist').html(resultHtml.find('#productlist').html());
        $('#num_results').text(resultHtml.find('#num_results').text());
      } else {
        $('.filter-loading, .infinite-scroll, .filter-labels').remove();
        $('#filters_no_results').show();
        $('#productlist').html(resultHtml.find('#productlist').html());
        $('#num_results').text('0');
      }

      filterInit();
    });
  }

  productBlocks.on('click', '.filter-reset', function () {
    $(this).addClass('hidden');
    $('#productlist_filters .remove-filter').click();
    return false;
  });

  // collapse/expand groups and update cookie
  productBlocks.on('click', '.toggle-panelbody', function () {
    var heading = $(this).parent('.panel-heading'),
        panel = heading.parent('.panel'),
        groupId = filterGroupId(panel);

    if (panel.hasClass('group-closed')) {
      filterGroupShow(panel);
      if (groupId === 'filterpanel') {
        filterGroupShow(productBlocks.find('.group-categories'));
      }
    } else {
      filterGroupHide(panel);
    }
    $.cookie('filter', filterOpen.join(','));

    return false;
  });

  // if checkbox clicked, but disabled, cancel input
  productBlocks.on('click', 'input[type=checkbox]', function () {
    var self = $(this),
        item = self.parents('li');

    if (item.hasClass('disabled')) {
      return false;
    }
  });

  // if checkbox changed, query server for new document
  productBlocks.on('change', 'input[type=checkbox]', function () {
    var self = $(this),
        item = self.parents('li'),
        list = self.parents('ul'),
        others = list.find('input[type=checkbox]').not(this);

    if (item.hasClass('disabled')) {
      return false;
    }

    // forecast classnames from filter result
    item.addClass('selected').removeClass('disabled');
    others.each(function () {
      $(this).parents('li').addClass('disabled').removeClass('selected');
    });

    // make query and update sidebar
    filterSubmit();
  });

  // if select dropdown is changed, query server for new document
  productBlocks.on('change', 'select', function () {
    var name = $(this).attr('id'),
        prefix = name.substr(0, name.length - 1),
        to,
        from;

    // if event element is a "from" select, lookup related "to"

    if (name.substr(-1) === 'f') {
      to = $('#' + prefix + 't');
      from = $(this);
    }

    if (name.substr(-1) === 't') {
      to = $(this);
      from = $('#' + prefix + 'f');
    }

    if (to && from) {
      // make sure we have a valid range before submit
      if (to.val().length > 0 && from.val().length > 0) {
        filterSubmit();
      }
    }
  });

  // active-filter labels: remove filter
  productBlocks.on('click', '.remove-filter', function () {
    var optionId = $(this).data('option');
    $('option[data-option="' + optionId + '"]').removeAttr('selected'); // reset matching range selects
    $('input[data-option="' + optionId + '"]').prop('checked', false); // uncheck matching boxes
    $(this).parent('.label').remove(); // remove from active filter list
    filterSubmit(); // update view
    return false;
  });

  // initialize filters
  if ($('#productlist_sidebar').size()) {

    $(window).on('resize', filterInit);

    filterInit();
  } else {

    $('.infinite-scroll').jscroll({
      loadingHtml: '<img src="' + loading + '" alt="Loading ...">',
      padding: -200,
      nextSelector: 'a.infinite-next:last',
      contentSelector: '.result-set'
    });
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],3:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

$(function () {

  $('#cookiebar').cookieBar({
    closeButton: '.btn'
  });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],4:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  var attendee_template = $('#attendee_template').html();

  $('.event-signup-form').each(function () {
    var eventForm = $(this),
        list = eventForm.find('.event-attendees'),
        persons = eventForm.find('textarea[name="persons"]');

    var updateList = function updateList() {
      var value = '';
      list.find('.input-group').each(function () {
        $(this).find('input').each(function () {
          value += $(this).val() + ' ';
        });
        value += '\n';
      });
      persons.val(value);
    };

    eventForm.on('click', '.add-attendee', function () {
      list.append(attendee_template);
      list.find('li').last().find('input').focus();
    });
    eventForm.on('click', '.remove_attendee', function () {
      $(this).parents('li').remove();
      updateList();
    });
    eventForm.on('blur', '.event-attendee input', function () {
      updateList();
    });
    eventForm.on('keyup', '.event-attendee input', function () {
      updateList();
    });

    eventForm.find('[type="submit"]').prop('disabled', false);

    eventForm.on('submit', function () {
      $('#event_success').addClass('hidden');
      $('#event_error').addClass('hidden');
      eventForm.find('[type="submit"]').prop('disabled', true);
      eventForm.find('.event_loading').removeClass('hidden');

      if (validateEmail(eventForm.find('input[name="email"]').val())) {
        eventForm.find('input[name="email"]').parents('.form-group').removeClass('has-error');

        if (eventForm.find('input[name="privacy"]:checked').size()) {
          eventForm.find('input[name="privacy"]').parents('.form-group').removeClass('has-error');

          $.post(eventForm.attr('action'), eventForm.serialize(), function (resp) {
            eventForm.find('.event_loading').addClass('hidden');

            if (resp.success) {
              eventForm.find('.event_fields').addClass('hidden');
              eventForm.find('.event_success').removeClass('hidden');
              eventForm.get(0).reset();
            } else {
              eventForm.find('.event_error').removeClass('hidden');
            }
          });
        } else {
          eventForm.find('input[name="privacy"]').parents('.form-group').addClass('has-error');
        }
      } else {
        eventForm.find('input[name="email"]').parents('.form-group').addClass('has-error');
      }

      return false;
    });
  });

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],5:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  App.flyout = $('#contact_flyout');

  App.flyout.on('click', '.contact-flyout-handle', function () {
    App.flyout.removeClass('closed');
    return false;
  });

  App.flyout.on('click', '.close-flyout', function () {
    App.flyout.addClass('closed');
    return false;
  });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],6:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  var gewinnspiel = $('.gewinnspiel');
  var errorInfo = $('#gewinnspiel_error');
  errorInfo.click(function () {
    $(this).hide();
  });

  var thanks = $('#gewinnspiel_thanks');
  thanks.click(function () {
    $(this).hide();
  });

  gewinnspiel.on('submit', 'form', function () {
    var form = $(this);
    var sub = form.find('[type="submit"]');

    if (!$(this).find('.at-error').size()) {
      $.post(form.attr('action'), form.serialize(), function (result) {

        if (result.error == 'already_signed_up') {
          if ($(window).width() <= 768) {
            var message = $.trim(errorInfo.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
            alert(message);
          } else {
            errorInfo.show();
          }
        } else {
          if ($(window).width() <= 768) {
            var message = $.trim(thanks.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
            alert(message);
          } else {
            thanks.show();
          }
        }

        sub.removeAttr('disabled').prop('disabled', false);
        sub.text(sub.data('text-default'));
      });
    }
    return false;
  });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],7:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {
  var jobform = $('form.jobform');
  if (jobform.size()) {

    var filelists = jobform.find('.files');
    var queues = jobform.find('.files-queue');
    var buttons = jobform.find('.buttons');
    var endpoint = '/pubuploads/index.php';
    var submit = jobform.find('button[type="submit"]');
    var reset = jobform.find('button[type="reset"]');
    var uniqId = 0;

    var lang = {
      upload: 'hochladen',
      aborted: 'abgebrochen',
      done: 'okay',
      failed: 'fehlgeschlagen',
      loading: 'warten ...',
      err_bewerbung: 'Bitte laden Sie Ihr Bewerbungsschreiben hoch.',
      err_lebenslauf: 'Bitte laden Sie einen Lebenslauf hoch.',
      err_toscheck: 'Bitte akzeptieren Sie unsere Datenschutzerklärung',
      jobform_failed: 'Das Senden der Bewerbung ist leider fehlgeschlagen.',
      jobform_success: 'Vielen Dank! Sie erhalten in Kürze ein Bestätigungs per E-Mail.'
    };

    var defaultConfig = {
      dataType: 'json',
      autoUpload: false,
      //acceptFileTypes: /(\.|\/)(gif|jpe?g|png|pdf)$/i,
      maxFileSize: 10000000 // 10 MB
    };

    var uploads = {
      bewerbung: {
        config: _.defaults({
          url: endpoint + '?type=bewerbung'
        }, defaultConfig)
      },
      lebenslauf: {
        config: _.defaults({
          url: endpoint + '?type=lebenslauf'
        }, defaultConfig)
      },
      zeugnisse: {
        config: _.defaults({
          url: endpoint + '?type=zeugnisse'
        }, defaultConfig)
      },
      sonstiges: {
        config: _.defaults({
          url: endpoint + '?type=sonstiges'
        }, defaultConfig)
      }
    };

    uploads.bewerbung.section = jobform.find('.files-type.bewerbung');
    uploads.lebenslauf.section = jobform.find('.files-type.lebenslauf');
    uploads.zeugnisse.section = jobform.find('.files-type.zeugnisse');
    uploads.sonstiges.section = jobform.find('.files-type.sonstiges');

    uploads.bewerbung.queue = jobform.find('.files-queue.bewerbung');
    uploads.lebenslauf.queue = jobform.find('.files-queue.lebenslauf');
    uploads.zeugnisse.queue = jobform.find('.files-queue.zeugnisse');
    uploads.sonstiges.queue = jobform.find('.files-queue.sonstiges');

    uploads.bewerbung.input = jobform.find('.files-input.bewerbung');
    uploads.lebenslauf.input = jobform.find('.files-input.lebenslauf');
    uploads.zeugnisse.input = jobform.find('.files-input.zeugnisse');
    uploads.sonstiges.input = jobform.find('.files-input.sonstiges');

    uploads.bewerbung.output = jobform.find('.files-links.bewerbung');
    uploads.lebenslauf.output = jobform.find('.files-links.lebenslauf');
    uploads.zeugnisse.output = jobform.find('.files-links.zeugnisse');
    uploads.sonstiges.output = jobform.find('.files-links.sonstiges');

    uploads.bewerbung.input.fileupload(uploads.bewerbung.config);
    uploads.lebenslauf.input.fileupload(uploads.lebenslauf.config);
    uploads.zeugnisse.input.fileupload(uploads.zeugnisse.config);
    uploads.sonstiges.input.fileupload(uploads.sonstiges.config);

    var uploadButton = $('<button/>').addClass('btn btn-default btn-xs up').prop('disabled', true).text(lang.loading).on('click', function () {
      var $this = $(this),
          data = $this.data();
      $this.off('click').text(lang.abort).on('click', function () {
        $this.text(lang.aborted);
        $this.prop('disabled', true);
        data.abort();
      });
      data.submit().always(function () {
        $this.text(lang.done);
        $this.prop('disabled', true);
      });
      return false;
    });

    var deleteButton = $('<button/>').addClass('btn btn-default btn-xs rm').html('&times;').on('click', function () {
      var $this = $(this),
          data = $this.data(),
          context = $('#file-' + data.uniqId),
          fileData = context.find('.file').data();

      if (typeof fileData.url !== 'undefined') {
        var currentUrls = uploads[data.type].output.val();
        currentUrls = currentUrls.replace(fileData.url + '\n', '');
        uploads[data.type].output.val(currentUrls);
      }

      context.remove();

      if (uploads[data.type].queue.find('li').size() == 0) {
        uploads[data.type].section.addClass('hidden');
      }

      var empty = 0;
      queues.each(function () {
        if ($(this).find('li').size() === 0) {
          empty++;
        }
      });

      if (empty == queues.size()) {
        filelists.addClass('hidden');
      }

      return false;
    });

    buttons.find('.files-input').on('fileuploadadd', function (e, data) {
      var type = $(e.target).data('type');

      data.type = type;
      data.uniqId = uniqId;
      data.context = $('<li/>').attr('id', 'file-' + uniqId).appendTo(uploads[type].queue);

      uniqId++;

      $.each(data.files, function (index, file) {
        var node = $('<div/>').addClass('file clearfix');
        file.uniqId = uniqId;

        if (!index) {
          var btns = $('<div/>').addClass('fbtn pull-left');
          btns.append(uploadButton.clone(true).data(data));
          btns.append(deleteButton.clone(true).data(data));
          node.append(btns);
        }

        node.append($('<div/>').addClass('fname').text(file.name));
        node.appendTo(data.context);
      });

      uploads[type].section.removeClass('hidden');
      filelists.removeClass('hidden');
    }).on('fileuploadprocessalways', function (e, data) {
      var type = $(e.target).data('type');
      var index = data.index,
          file = data.files[index],
          node = $(data.context.children()[index]);
      if (file.error) {
        node.find('.fname').append('<br>').append($('<span class="ferr text-danger"/>').text(file.error));
      }
      if (index + 1 === data.files.length) {
        data.context.find('button.up').text(lang.upload).prop('disabled', !!data.files.error);
      }
    }).on('fileuploaddone', function (e, data) {
      var type = $(e.target).data('type');
      $.each(data.result['f_' + type], function (index, file) {
        var context = $(data.context.children()[index]);
        var filename = context.find('.fname');
        if (file.url) {
          context.data('url', file.url);
          var currentUrls = uploads[type].output.val();
          uploads[type].output.val(currentUrls + file.url + "\n");

          filename.append(' ');
          filename.append('<i class="glyphicon glyphicon-ok"></i>');
          filename.wrap('<span class="text-success"/>');
        } else if (file.error) {
          var error = $('<span class="ferr text-danger"/>').text(file.error);
          filename.append(' ');
          filename.append('<i class="glyphicon glyphicon-remove"></i>');
          filename.append('<br>').append(error);
        }
      });
    }).on('fileuploadfail', function (e, data) {
      $.each(data.files, function (index) {
        var filename = $(data.context.children()[index]).find('.fname');
        var error = $('<span class="ferr text-danger"/>').text(lang.failed);
        filename.append('<br>').append(error);
      });
    }).prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');

    jobform.on('submit', function (e) {

      if (!$('input[name="tos"]').prop('checked')) {
        alert(lang.err_toscheck);
        return false;
      }
      if (uploads.bewerbung.output.val().length <= 5) {
        alert(lang.err_bewerbung);
        return false;
      }
      if (uploads.lebenslauf.output.val().length <= 5) {
        alert(lang.err_lebenslauf);
        return false;
      }

      submit.prop('disabled', true);
      $.post($(this).attr('action'), $(this).serialize(), function (res) {
        submit.prop('disabled', false);

        if (res.success) {
          queues.find('.rm').click();
          reset.click();
          alert(lang.jobform_success);
        } else if (res.error) {
          alert(lang.jobform_failed + '\n' + res.error);
        } else {
          alert(lang.jobform_failed);
        }
      });

      return false;
    });
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],8:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

App.menu.find('button.dropdown-toogle').on('click', function () {
  $(this).parents('li').toggleClass('open');
});

App.setMenuClass = function () {
  window.setTimeout(function () {
    if (App.menu.attr('aria-expanded') === 'true') {
      App.body.addClass('menu-visible');
    } else {
      App.body.removeClass('menu-visible');
    }
  }, 50);
};

$(function () {
  App.mainnav.on('click', '.navbar-toggle', App.setMenuClass);

  $(window).on('resize', App.setMenuClass);

  $('.navbar .dropdown > a').click(function () {
    if ($(window).width() >= 1200) {
      if ((this.target + '').length) {
        return true;
      }
      location.href = this.href;
      return false;
    }
  });

  // init
  App.setMenuClass();
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],9:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  $('#news').on('click', '.more', function () {
    $('#newsdetail').modal('show');
  });

  $('.monacor .news-item a').height('').equalizeHeight();
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],10:[function(require,module,exports){
'use strict';

$('.newsletter-form form').on('submit', function (e) {
  e.preventDefault();

  var form = $(this),
      url = form.attr('action'),
      button = form.find('[type="submit"]'),
      thankyou = $('#newsletter_thanks');

  if ($(this).find('.at-error').size()) {
    return false;
  }

  $.post(url, form.serialize(), function (result) {
    form.get(0).reset();

    var btn = form.find('[type="submit"]');
    btn.html(btn.data('old-value'));
    btn.prop('disabled', false);

    if (result.success) {
      thankyou.modal('show');
    }
  });

  return false;
});

$('.newsletter-small form').on('submit', function (e) {
  e.preventDefault();

  var form = $(this),
      thankyou = $($(this).data('thanks')),
      url = form.attr('action'),
      button = form.find('[type=submit]');

  if (!button.data('unlocked')) {
    return false;
  }
  if ($(this).find('.at-error').size()) {
    return false;
  }

  $.post(url, form.serialize(), function (result) {
    form.get(0).reset();

    var btn = form.find('[type=submit]');
    btn.html(btn.data('old-value'));
    btn.prop('disabled', false);

    if (result.success) {
      thankyou.modal('show');
    }
  });

  return false;
});

$('#subscribe_thanks').on('click', function () {
  $(this).hide();
});
$('.newsletter form').on('submit', function () {
  var form = $(this),
      thanks = $('#subscribe_thanks'),
      button = form.find('[type=submit]');

  if (!button.data('unlocked')) {
    return false;
  }
  if ($(this).find('.at-error').size()) {
    return false;
  }

  $.post(form.attr('action'), form.serialize(), function (result) {
    form.get(0).reset();

    if ($(window).width() <= 768) {
      var message = $.trim(thanks.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
      alert(message);
    } else {
      thanks.show();
    }
    var sub = form.find('[type="submit"]');
    sub.removeAttr('disabled').prop('disabled', false);
    sub.text(sub.data('text-default'));
  });

  return false;
});

},{}],11:[function(require,module,exports){
'use strict';

var thankyou = $('#orderform_thanks');

$('.order-form form').on('submit', function (e) {
  e.preventDefault();

  var form = $(this),
      url = form.attr('action'),
      extras = form.find('.extra-options');

  if (extras.size()) {
    extras.find('.at-error').remove();

    if (!extras.find('input[type="checkbox"]:checked').size()) {
      extras.prepend('<p class="at-error">' + extras.data('message') + '</p>');

      var btn = form.find('[type=submit]');
      btn.html(btn.data('old-value'));
      btn.prop('disabled', false);

      return false;
    }
  }

  if (!form.find('input[name="read_privacy"]').is(':checked')) {
    form.find('label[for="privacy"]').focus();
    return false;
  }

  $.post(url, form.serialize(), function (result) {
    form.get(0).reset();

    var btn = form.find('[type=submit]');
    btn.html(btn.data('old-value'));
    btn.prop('disabled', false);

    if (result.success) {
      thankyou.modal('show');
    }
  });
});

},{}],12:[function(require,module,exports){
'use strict';

var tagged_elements = {};
var grids = $('.mo-lgrid');

grids.each(function () {
  var grid = $(this);
  var grid_items = grid.find('.lgrid-item');
  var tag_display = grid.find('.lgrid-tag-header');
  var tag_display_name = grid.find('.lgrid-tag-name');

  var selected = null;

  grid_items.find('.tag').each(function () {
    var tag = $(this);
    var item = tag.parents('.lgrid-item');
    var name = tag.text().toLowerCase();
    if (typeof tagged_elements[name] === 'undefined') {
      tagged_elements[name] = [];
    }
    tagged_elements[name].push(item);
  });

  grid_items.on('click', '.tag', function () {
    var tag = $(this);
    var displayName = tag.text();
    var name = displayName.toLowerCase();

    if (typeof tagged_elements[name] === 'undefined') {
      grid_items.show();
      tag_display.addClass('hidden');
      tag_display_name.text('');
    } else {
      grid_items.hide();
      selected = name;
      tag_display_name.text(displayName);
      tag_display.removeClass('hidden');

      $.each(tagged_elements[name], function () {
        $(this).show();
      });
    }

    return false;
  });

  grid.on('click', '.lgrid-tag-reset', function () {
    grid_items.show();
    tag_display.addClass('hidden');
    tag_display_name.text('');
  });
});

},{}],13:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
$(function () {
    var form = $(".password-downloads");
    var button = form.find('button');
    var output = $('#download_links');
    var wrong_pass = $('#password_wrong');

    var captchaInput = form.find('.captcha-input');
    captchaInput.realperson({
        length: 6,
        regenerate: form.find('.captcha-regen').data('value')
    });

    form.on('submit', function (e) {
        button.attr('disabled', 'disabled');
        button.text('Please wait...');
        output.html('');
        wrong_pass.hide();
        e.preventDefault();
        $.post(this.action, $(this).serialize(), function (resp) {
            window.setTimeout(function () {
                button.removeAttr('disabled');
                button.text(button.data('label'));
            }, 1000);

            if (resp.success) {
                if (resp.result && resp.result.length > 0) {
                    // build download list
                    var html = '<h2 class="text-primary">' + output.data('label') + '</h2><div class="list-group">';
                    $.each(resp.result, function (i, item) {
                        html += '<div class="list-group-item"><i class="fa fa-download" style="margin-right:4px;"></i> ';
                        html += '<a href="' + item.url + '" target="_blank">' + item.name + '</a></div>';
                    });
                    html += '</div>';

                    // send to doc
                    output.html(html);
                }
            } else {
                wrong_pass.show();
            }
        });
        return false;
    });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],14:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  $('.video-modal').on('hide.bs.modal', function (e) {
    var modal = $(e.currentTarget),
        video = modal.find('video');
    if (video.length) {
      video.get(0).pause();
    }
  });

  var thumbnails = App.content.find('.images .thumbnails');
  thumbnails.on('click', 'a', function () {
    thumbnails.find('a').removeClass('active');
    $(this).addClass('active');
  });

  var product = $('#product');
  if (product.size()) {
    var gallery = $('#gallery');
    var galleryslide = $('#galleryslide');
    var galleryIndex = 0;

    // when gallery modal request, update current index
    product.find('.show-zoom-gallery').on('click', function () {
      galleryIndex = $(this).data('index');
    });

    // on modal opened
    gallery.on('shown.bs.modal', function () {
      // slide to current index
      gallery.find('.thumbnails').find('a[data-slide-to=' + galleryIndex + ']').click();
    });

    // on modal hidden
    gallery.on('hide.bs.modal', function () {
      // reset all iframes (that may be playing music)
      galleryslide.find('iframe').each(function () {
        var frame = $(this).clone();
        $(this).after(frame);
        $(this).remove();
      });
    });

    // on modal carousel sliding (by click)
    galleryslide.on('slide.bs.carousel', function () {
      // reset all iframes (that may be playing music)
      galleryslide.find('.active iframe').each(function () {
        var frame = $(this).clone();
        $(this).after(frame);
        $(this).remove();
      });
    });

    // only show mouse-over zoom on large desktops
    if ($(window).width() > 980) {
      product.find('.zoomable').elevateZoom({
        responsive: true,
        zoomLevel: 1, //default zoom level of image
        //scrollZoom: true, //allow zoom on mousewheel, true to activate
        //scrollZoomIncrement: 0.1,  //steps of the scrollzoom
        //easing: true,
        //easingAmount: 12,
        zoomWindowWidth: 600,
        zoomWindowHeight: 500,
        zoomWindowOffetx: 150,
        zoomWindowOffety: -20,
        zoomWindowPosition: 1,
        borderSize: 2
      });
    }

    var tabs = product.find('.tabs');
    product.on('click', '.tabs a', function () {
      var tabsOffset = 160;
      tabs.find('a').removeClass('active');
      $(this).addClass('active');
      var targetOffset = $($(this).attr('href')).offset().top;
      $('html, body').stop().animate({
        scrollTop: targetOffset - tabsOffset
      }, 'slow');
      return false;
    });

    product.on('click', '.accordion-tab', function () {
      var toggle = $(this);
      var target = toggle.data('target');
      var related = $(target);

      if (toggle.hasClass('active')) {
        toggle.removeClass('active');
        related.removeClass('active');
        related.slideUp(300);
      } else {
        $('#product').find('.accordion-tab.active').click();
        toggle.addClass('active');
        related.addClass('active');
        related.hide().slideDown(300, function () {
          $.smoothScroll({
            offset: -55,
            scrollTarget: target
          });
        });
      }

      return false;
    });

    product.on('click', 'a.more-supplies', function () {
      var link = $(this);

      $('#moresupplies').fadeToggle(300, function () {
        if ($('#moresupplies').is(':visible')) {
          link.text(link.data('text-less'));
        } else {
          link.text(link.data('text-more'));
        }
      });

      $('.eq-elements').each(function () {
        $(this).find('.eq-element').equalizeHeight();
      });
      return false;
    });
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],15:[function(require,module,exports){
'use strict';

var projectform = $('.projectform');
if (projectform.size()) {

    var fileinput = projectform.find('.file-input');
    var filequeue = projectform.find('.file-queue');
    var fileoutput = projectform.find('.file-output');

    var upload = null;
    var type = 'projekt';
    var uniqId = 0;

    projectform.find('[type="submit"]').each(function () {
        var button = $(this);

        button.data('unlocked', false);
        button.attr('disabled', 'disabled').prop('disabled', true);

        window.projectformUnlock = $.proxy(function () {
            button.removeAttr('disabled').prop('disabled', false);
            button.data('unlocked', true);
        }, this);
    });

    projectform.find('form').on('submit', function (e) {
        e.preventDefault();

        var form = $(this),
            thankyouSelector = form.data('thanks'),
            thankyou = $(thankyouSelector),
            url = form.attr('action') + '&' + new Date().getTime(),
            button = form.find('[type=submit]');

        if (!button.data('unlocked')) {
            return false;
        }

        if ($(this).find('.at-error').size()) {
            return false;
        }

        $.post(url, form.serialize(), function (result) {
            var btn = form.find('[type=submit]');
            btn.html(btn.data('old-value'));
            btn.prop('disabled', false);

            if (result.success) {
                form.get(0).reset();
                filequeue.find('.rm').click();
                thankyou.modal('show');
            }
        });

        return false;
    });

    var lang = {
        upload: 'hochladen',
        abort: '...',
        aborted: 'abgebrochen',
        done: 'okay',
        failed: 'fehlgeschlagen',
        loading: 'warten ...',
        err_bewerbung: 'Bitte laden Sie Ihr Bewerbungsschreiben hoch.',
        err_lebenslauf: 'Bitte laden Sie einen Lebenslauf hoch.',
        err_toscheck: 'Bitte akzeptieren Sie unsere Datenschutzerklärung',
        jobform_failed: 'Das Senden der Bewerbung ist leider fehlgeschlagen.',
        jobform_success: 'Vielen Dank! Sie erhalten in Kürze ein Bestätigungs per E-Mail.'
    };

    fileinput.fileupload({
        //acceptFileTypes: /(\.|\/)(gif|jpe?g|png|pdf)$/i,
        url: '/pubuploads/index.php?type=' + type,
        dataType: 'json',
        autoUpload: false,
        maxFileSize: 10000000, // 10 MB
        queue: filequeue,
        paramName: 'f_' + type
    });

    upload = fileinput.data('blueimpFileupload');

    var uploadButton = $('<button/>').addClass('btn btn-default btn-xs up').prop('disabled', true).text(lang.loading).on('click', function () {
        var $this = $(this),
            data = $this.data();
        $this.off('click').text(lang.abort).on('click', function () {
            $this.text(lang.aborted);
            $this.prop('disabled', true);
            data.abort();
        });

        data.submit().always(function () {
            $this.text(lang.done);
            $this.prop('disabled', true);
        });
        return false;
    });

    var deleteButton = $('<button/>').addClass('btn btn-default btn-xs rm').html('&times;').on('click', function (e) {
        var $this = $(this),
            data = $this.data(),
            context = $('#file-' + data.uniqId),
            fileData = context.find('.file').data();

        if (typeof fileData.url !== 'undefined') {
            var currentUrls = fileoutput.val();
            currentUrls = currentUrls.replace(fileData.url + '\n', '');
            fileoutput.val(currentUrls);
        }

        context.remove();
        upload._trigger('destroyed', e, fileData);

        return false;
    });

    fileinput.on('fileuploadadd', function (e, data) {

        data.type = type;
        data.uniqId = uniqId;
        data.context = $('<li/>').attr('id', 'file-' + uniqId).appendTo(filequeue);

        uniqId++;

        $.each(data.files, function (index, file) {
            var node = $('<div/>').addClass('file clearfix');

            if (!index) {
                var btns = $('<div/>').addClass('fbtn pull-left');
                btns.append(uploadButton.clone(true).data(data));
                btns.append(deleteButton.clone(true).data(data));
                node.append(btns);
            }

            file.uniqId = uniqId;
            node.append($('<div/>').addClass('fname').text(file.name));
            node.appendTo(data.context);
        });
    });

    fileinput.on('fileuploadprocessalways', function (e, data) {
        var index = data.index,
            file = data.files[index],
            node = $(data.context.children()[index]);

        if (file.error) {
            node.find('.fname').append('<br>').append($('<span class="ferr text-danger"/>').text(file.error));
        }

        if (index + 1 === data.files.length) {
            data.context.find('button.up').text(lang.upload).prop('disabled', !!data.files.error);
        }
    });

    fileinput.on('fileuploaddone', function (e, data) {

        $.each(data.result['f_' + type], function (index, file) {
            var context = $(data.context.children()[index]);
            var filename = context.find('.fname');
            if (file.url) {
                context.data('url', file.url);
                var currentUrls = fileoutput.val();
                fileoutput.val(currentUrls + file.url + "\n");

                filename.append(' ');
                filename.append('<i class="glyphicon glyphicon-ok"></i>');
                filename.wrap('<span class="text-success"/>');
            } else if (file.error) {
                var error = $('<span class="ferr text-danger"/>').text(file.error);
                filename.append(' ');
                filename.append('<i class="glyphicon glyphicon-remove"></i>');
                filename.append('<br>').append(error);
            }
        });
    });

    fileinput.on('fileuploadfail', function (e, data) {
        $.each(data.files, function (index) {
            var filename = $(data.context.children()[index]).find('.fname');
            var error = $('<span class="ferr text-danger"/>').text(lang.failed);
            filename.append('<br>').append(error);
        });
    });

    fileinput.prop('disabled', !$.support.fileInput).parent().addClass($.support.fileInput ? undefined : 'disabled');
}

},{}],16:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  var element = App.content.find('.retailmap');

  if (element.size()) {
    var data = window._retailmapPool;

    var infowindow = new google.maps.InfoWindow();
    var retailmap = new google.maps.Map(document.getElementById('retailmap'), {
      center: { lat: 51.165691, lng: 10.451526000000058 },
      zoom: 6,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      scaleControl: true
    });

    element.on('geocode_done', function (e, locations) {
      $.each(locations, function (i, retailer) {
        var marker = new google.maps.Marker({
          map: retailmap,
          position: retailer.location
        });
        google.maps.event.addListener(marker, 'click', function () {
          var content = '<b>' + retailer.name + '</b>';
          content += '<br>' + retailer.address.replace(/\n/g, '<br>');

          if (retailer.tel || retailer.email) {
            content += '<br>';
          }
          if (retailer.tel) {
            content += '<br>T: ' + retailer.tel;
          }
          if (retailer.email) {
            content += '<br>M: ' + retailer.email;
          }

          infowindow.setContent(content);
          infowindow.open(retailmap, this);
        });
      });
    });

    var queried = 0;
    var geocoder = new google.maps.Geocoder();
    var _data = [];
    $.each(data, function (i, retailer) {
      geocoder.geocode({ address: retailer.address, region: 'de' }, function (results, status) {
        if (results && status === google.maps.GeocoderStatus.OK && results.length > 0) {
          data[i].location = results[0].geometry.location;
          data[i].latlng = results[0].geometry.location.toString();
          _data.push(data[i]);
        }
        queried++;

        if (queried === data.length) {
          element.trigger('geocode_done', [_data]);
        }
      });
    });
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],17:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {
  /**
   * Sicherheits Seiten
   */
  var securityNav = $('.security-nav');
  if (securityNav.size()) {
    var securityNavHeight = securityNav.outerHeight();
    var securityNavPosition = securityNav.offset().top - securityNavHeight;

    $(window).on('resize', function () {
      securityNavHeight = securityNav.outerHeight();
      securityNavPosition = securityNav.offset().top - securityNavHeight;
    });

    $(window).on('resize scroll', function () {
      if ($(window).scrollTop() >= securityNavPosition) {
        App.body.addClass('security-nav-sticky');
      } else {
        App.body.removeClass('security-nav-sticky');
      }
    });
  }

  // tabs
  var securityProducts = $('.security-products');
  if (securityProducts.size()) {

    // on page load add "active" to first product-map on the page
    securityProducts.first().addClass('active');

    // on click on any product-map header, remove the current "active" and make its parent "active".
    securityProducts.on('click', '.security-products-header', function () {
      var self = $(this).parents('.security-products');

      if (self.hasClass('active')) {
        self.removeClass('active');
      } else {
        securityProducts.filter('.active').removeClass('active');
        self.addClass('active');
      }

      $.smoothScroll({
        scrollTarget: self,
        offset: -70
      });
      return false;
    });

    // on click (tablet) oder mouseover show the hidden content card above the target hotspot
    var hotspotMouseover = function hotspotMouseover() {
      var content = $(this).find('.hotspot-content'),
          dot = $(this).find('.hotspot-dot'),
          contentWidth = content.outerWidth(),
          containerWidth = $(this).parents('.security-products').outerWidth(),
          horizDistance = Math.floor($(this).position().left);

      // content near left edge
      if (horizDistance < contentWidth) {
        content.css('margin-left', '-' + (horizDistance - 1) + 'px');
      }

      // content near right edge
      if (containerWidth - horizDistance < contentWidth) {
        content.css('margin-left', '-' + Math.floor(containerWidth - horizDistance + contentWidth / 4 - 50) + 'px');
      }

      $(this).addClass('active');

      return false;
    };

    securityProducts.on('click', '.hotspot', hotspotMouseover);
    securityProducts.on('mouseenter', '.hotspot', hotspotMouseover);
    securityProducts.on('mouseleave', '.hotspot', function () {
      $(this).removeClass('active');
    });
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],18:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  // smooth scroll any anchor with the class "smooth"
  App.element.find('a.smooth').click(function () {
    $.smoothScroll({
      scrollTarget: $(this).attr('href'),
      offset: -50
    });
    return false;
  });

  // smooth scroll any hash link (smooth "one-page" nav)
  App.mainnav.find('a[href*="/#"]').click(function () {
    var hash = $(this).prop('hash');
    if (!$(hash).size()) {
      return true;
    }
    $.smoothScroll({
      scrollTarget: hash,
      offset: -50
    });
    return false;
  });

  // scroll to section
  App.element.find('.scroll-to').on('click', function (event) {
    event.preventDefault();
    var target = this.hash,
        $target = $(target);
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top - 50
    }, 900);
  }).trigger('resize');

  // scroll to product tab
  App.element.find('.scroll-to-product-tab').on('click', function (event) {
    event.preventDefault();
    $('.accordion-tab[data-target="' + this.hash + '"]').click();
  }).trigger('resize');

  // scroll on page-load, when hash is present
  if (document.location.hash) {
    var hash = document.location.hash;
    if ($(hash).size()) {
      $.smoothScroll({
        scrollTarget: hash,
        offset: -50
      });
    }
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],19:[function(require,module,exports){
'use strict';

$('.element').each(function () {
  var ce = $(this),
      selectorArticle = '.socialmedia-article',
      selectorVideo = selectorArticle + ' .socialmedia-video',
      selectorPlay = selectorArticle + ' .socialmedia-playbutton',
      selectorMore = '.socialmedia-load-more';

  // video interaction
  // ------------------------------------------------

  ce.on('mouseenter focus', selectorVideo, function () {
    var wrap = $(this);
    if (wrap.hasClass('playing')) {
      // add controls when playing + mouseover
      wrap.find('video').attr("controls", "");
    }
  });

  ce.on('mouseleave blur', selectorVideo, function () {
    $(this).find('video').removeAttr("controls");
  });

  ce.on('click tap', selectorVideo, function () {
    var wrap = $(this);
    var video = wrap.find('video');

    if (video.prop('paused')) {
      video.get(0).play();wrap.addClass('playing');
    } else {
      video.get(0).pause();wrap.removeClass('playing');
    }
  });

  // video play button
  // ------------------------------------------------
  ce.on('mouseleave', selectorPlay, function () {
    var wrap = $(this).parent();
    if (!wrap.hasClass('playing')) {
      wrap.find('video').removeAttr("controls");
    }
  });

  // loadm more button
  // ------------------------------------------------
  ce.on('click tap', selectorMore, function () {
    var button = $(this),
        settings = $(this).data();
    var nextpage = settings.page + 1;
    var nexturl = document.location.pathname + '?no_cache=1&page=' + nextpage + '&dt=' + new Date().getTime();
    var container = $(settings.target);

    button.addClass('disabled').prop('disabled', true);

    $.get(nexturl, function (html) {
      var $document = $(html);
      var nextgrid = $document.find(settings.target);
      var nextposts = nextgrid.find(selectorArticle);
      var nextbutton = $document.find(selectorMore);
      var page = nextbutton.data('page');

      container.append(nextposts);

      nextposts.find('.carousel').carousel(); // enable carousel
      button.data('page', page).attr('data-page', page); // update params
      button.removeClass('disabled').prop('disabled', false); // make clickable
      if (page === settings.numpages) {
        button.hide();
      } // hide on last-page
    });

    return false;
  });
});

},{}],20:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var headerOffset = 0;
var forcedOffset = App.header.data('scrolling');

$(function () {
  var productTabs = $('#product .tabs');
  if (productTabs.size()) {

    var productTabsHeight = productTabs.outerHeight();
    var productTabsPosition = productTabs.offset().top;

    $(window).on('resize', function () {
      productTabsHeight = productTabs.outerHeight();
      productTabsPosition = productTabs.offset().top;
    });

    $(window).on('resize scroll', function () {
      var bodyPadd = parseInt(App.body.css('padding-top'), 10);
      if ($(window).scrollTop() >= productTabsPosition - bodyPadd + headerOffset) {
        App.body.addClass('product-tabs-sticky');
      } else {
        App.body.removeClass('product-tabs-sticky');
      }
    });
  }

  // trigger once on dom-ready
  $(window).trigger('resize');
});

$(window).on('resize', function () {
  headerOffset = App.header.offset().top;
  if (App.mininav.is(':visible')) {
    headerOffset += App.mininav.outerHeight();
  }
});

$(window).on('resize scroll', function () {

  if (forcedOffset) {
    headerOffset = forcedOffset;
  }

  App.body.toggleClass('scrolling', $(window).scrollTop() > headerOffset);
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],21:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

$(function () {

  $('.support').find('.show-contact-form').on('click', function () {
    var supportRow = $(this).parents('.support'),
        supportForm = supportRow.find('.support-contact-form');

    supportForm.find('.support-recipient').val($(this).data('mail'));
    supportForm.toggle();

    return false;
  });

  $('.support-contact-form').find('.contactform-cancel').on('click', function () {
    $(this).parents('.support-contact-form').hide();
  });

  $('.support-contact-form').find('.infomessage-close').on('click', function () {
    $(this).parents('.support-contact-message').hide();
  });

  $('.support').find('.support-contact-form form').on('submit', function () {
    var formElement = $(this),
        supportRow = $(this).parents('.support'),
        thanksMessage = supportRow.find('.support-contact-message'),
        submitButton = supportRow.find('.contactform-submit');

    if (!formElement.find('.at-error').size()) {

      $.ajax({
        url: formElement.attr('action'),
        data: formElement.serialize(),
        method: 'POST'
      }).done(function () {
        if ($(window).width() <= 768) {
          var message = $.trim(thanksMessage.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
          alert(message);
        } else {
          thanksMessage.show();
        }
      }).always(function () {
        submitButton.removeAttr('disabled').prop('disabled', false);
        submitButton.text(submitButton.data('text-default'));
      });
    }
    return false;
  });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],22:[function(require,module,exports){
(function (global){
'use strict';

var $ = window.$ = window.jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
require('./utils/equalheight.js');

var App = {
  body: null,
  element: null,

  header: null,
  menu: null,
  content: null,
  mininav: null,
  mainnav: null,
  searchbar: null,

  modules: {},

  isMobile: function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
      return true;
    } else {
      return false;
    }
  }(navigator.userAgent || navigator.vendor || window.opera)
};

lightbox.option({
  'fadeDuration': 300,
  'resizeDuration': 200,
  'wrapAround': true,
  'disableScrolling': true,
  'albumLabel': ''
});

$(function () {
  FastClick.attach(document.body);

  App.body = $('body');
  App.element = $('#app');
  App.header = $('#header');
  App.menu = $('#menu');
  App.content = $('#content');
  App.mainnav = $('#mainnav');
  App.mininav = $('#mininav');
  App.searchbar = $('#searchbar');

  // section scripts
  App.modules = {
    Merkliste: require('./modules/merkliste.js'),
    Downloads: require('./modules/downloads.js'),
    Contact: require('./modules/contactform.js'),
    Retailers: require('./modules/retailer.js'),
    Facherrichter: require('./modules/facherrichter.js'),
    PrintOrder: require('./modules/printorder.js'),
    Searchbar: require('./modules/searchbar.js')
  };

  $('form.validate').valida({
    form_autocomplete: 'on',
    highlight: false,
    messages: window.Monacor.formMessages
  });

  // element scripts
  require('./elements/menu.js');
  require('./elements/sticky.js');
  require('./elements/smoothlink.js');
  require('./elements/flyout.js');
  require('./elements/news.js');
  require('./elements/carousel.js');
  require('./elements/product.js');
  require('./elements/gewinnspiel.js');
  require('./elements/catalog.js');
  require('./elements/jobform.js');
  require('./elements/support.js');
  require('./elements/cookiebar.js');
  require('./elements/retailmap.js');
  require('./elements/newsletter.js');
  require('./elements/orderform.js');
  require('./elements/sicherheit.js');
  require('./elements/projectform.js');
  require('./elements/socialmedia.js');
  require('./elements/events.js');
  require('./elements/pagegrid_long.js');
  require('./elements/events.js');
  require('./elements/password_download.js');

  require('./konfigurator/konfigurator.js');

  require('./utils/tracking.js');

  // start all modules
  $.each(App.modules, function (name, obj) {
    obj.initialize();
  });

  App.content.on('click', 'a[data-toggle="swap"]', function () {
    var link = $(this),
        target = $(link.data('target') || link.attr('href'));
    $(link.data('hide')).addClass('hidden');
    target.removeClass('hidden');
    return false;
  });

  $("#evtm").modal("show");

  // Trigger once for initialize
  $(window).trigger('resize');
  $(window).trigger('scroll');
  $(window).trigger('ready.monacor', App);
});

module.exports = App;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./elements/carousel.js":1,"./elements/catalog.js":2,"./elements/cookiebar.js":3,"./elements/events.js":4,"./elements/flyout.js":5,"./elements/gewinnspiel.js":6,"./elements/jobform.js":7,"./elements/menu.js":8,"./elements/news.js":9,"./elements/newsletter.js":10,"./elements/orderform.js":11,"./elements/pagegrid_long.js":12,"./elements/password_download.js":13,"./elements/product.js":14,"./elements/projectform.js":15,"./elements/retailmap.js":16,"./elements/sicherheit.js":17,"./elements/smoothlink.js":18,"./elements/socialmedia.js":19,"./elements/sticky.js":20,"./elements/support.js":21,"./konfigurator/konfigurator.js":27,"./modules/contactform.js":28,"./modules/downloads.js":29,"./modules/facherrichter.js":30,"./modules/merkliste.js":31,"./modules/printorder.js":32,"./modules/retailer.js":33,"./modules/searchbar.js":34,"./utils/equalheight.js":35,"./utils/tracking.js":36}],23:[function(require,module,exports){
'use strict';

var scenarios = $('.security-products'),
    scenariosModals = $('.security-product-modals'),
    scenariosModalBg = scenariosModals.find('.productselector-modal-bg'),
    mainnav = $('#mainnav');

scenarios.on('click', 'a[data-target]', function () {
  var modal = $($(this).data('target'));
  modal.removeClass('hidden');
  scenariosModalBg.removeClass('hidden');

  if (!modal.hasClass('initialized')) {
    var carousel = modal.find(".carousel");
    if (carousel.size()) {
      carousel.find('.product-figure').each(function () {
        var hammertime = new Hammer(this);
        hammertime.on('swipeleft', function (ev) {
          carousel.carousel('next');
        });
        hammertime.on('swiperight', function (ev) {
          carousel.carousel('prev');
        });
      });
    }
    modal.addClass('initialized');
  }
  return false;
});
scenariosModals.on('click', '.productselector-modal-close .btn', function () {
  var modal = $(this).parents('.productselector-modal');
  modal.addClass('hidden');
  scenariosModalBg.addClass('hidden');
  return false;
});

mainnav.find('.navbar-header').prepend(function () {
  var button = $(document.createElement('button'));
  var lang = $.trim($('.lang-name').text()).match(/^Deutsch/) ? 'de' : 'en';
  var base = document.location.pathname.split('/' + lang + '/')[0];
  var target = base + "/" + lang + "/audio-solution-guide/index.html#mcfg_scenarios";

  if (!document.location.href.match(/\/de\/|\/en\//)) {
    target = '/audio-solution-guide/#mcfg_scenarios';
  }

  button.prop('type', 'button');
  button.addClass('btn btn-primary pull-right productselector-backbtn back-scenarios');
  button.on('click', function () {
    document.location.href = target;
  });

  if (lang === 'de') {
    button.append("<span class='hidden-xs'>Zurück zur Übersicht</span>");
    button.attr('title', 'Szenarien');
  } else {
    button.append("<span class='hidden-xs'>Back to overview</span>");
    button.attr('title', 'Scenarios');
  }

  button.append('<span class="visible-xs"><i class="fa fa-th-large"></i></span>');

  return button;
});

mainnav.find('.navbar-header').prepend(function () {
  var button = $(document.createElement('button'));
  var lang = $.trim($('.lang-name').text()).match(/^Deutsch/) ? 'de' : 'en';
  var base = document.location.pathname.split('/' + lang + '/')[0];
  var target = base + "/" + lang + "/audio-solution-guide/index.html#mcfg_steps";

  if (!document.location.href.match(/\/de\/|\/en\//)) {
    target = '/audio-solution-guide/#mcfg_steps';
  }

  button.prop('type', 'button');
  button.addClass('btn btn-primary pull-right productselector-backbtn back-steps');
  button.on('click', function () {
    document.location.href = target;
  });

  if (lang === 'de') {
    button.append("<span class='hidden-xs'>Starte Konfigurator</span>");
    button.attr('title', 'Konfigurator');
  } else {
    button.append("<span class='hidden-xs'>Start configurator</span>");
    button.attr('title', 'Configurator');
  }

  button.append('<span class="visible-xs"><i class="fa fa-paper-plane-o"></i></span>');

  return button;
});

},{}],24:[function(require,module,exports){
'use strict';

var scenarioTab = $('section.productselector-type-scenarios');
var scenarioButtons = $('#scenarios');

function isMobileUI() {
  return $(window).width() < 768;
}

$('.scenario-buttons a').click(function () {
  var target = $($(this).attr('href'));
  var offset;

  if (isMobileUI()) {
    offset = target.offset().top;
    scenarioTab.stop().animate({
      'scrollTop': offset - 60
    }, 900);
  } else {
    offset = target.offset().top;
    $('html, body').stop().animate({
      'scrollTop': offset - (60 + 170)
    }, 900);
  }

  return false;
});

// smooth scroll any anchor with the class "smooth"
$('.scenario-back-to-top').click(function () {
  var offset;
  if (isMobileUI()) {
    offset = scenarioButtons.position().top;
    scenarioTab.stop().animate({
      'scrollTop': offset - 60
    }, 900);
  } else {
    offset = scenarioButtons.offset().top;
    $('html, body').stop().animate({
      'scrollTop': offset - 60
    }, 900);
  }

  return false;
});

scenarioTab.on('resize scroll', function () {
  scenarioTab.toggleClass('scrolling', scenarioTab.scrollTop() > 120);
});

$(window).on('resize scroll', function () {
  if (scenarioButtons.size() && scenarioTab.is(':visible')) {
    if (!isMobileUI()) {
      scenarioTab.toggleClass('make-sticky', $(window).scrollTop() >= scenarioButtons.offset().top - 75);
    } else {
      scenarioTab.removeClass('make-sticky');
    }
  }
});

},{}],25:[function(require,module,exports){
'use strict';

if (document.location.href.match(/\/de\/|\/en\//)) {

  console.log('electron mode');

  // disable logo click
  $('.navbar-brand').on('click', function () {
    return false;
  });

  // disable cookiebar
  $.cookie("cookiebar", "hide");
  $('#cookiebar').remove();

  // get language

  $('.language-dropdown').find('a').each(function () {
    var href = $(this).attr('href');
    var lang = $.trim($('.lang-name').text()).match(/^Deutsch/) ? 'de' : 'en';
    var base = document.location.pathname.split('/' + lang + '/')[0];

    href = href.replace('https://monacor-com.php7', base + '/en');
    href = href.replace('https://www.monacor.com', base + '/en');
    href = href.replace('https://monacor.php7', base + '/de');
    href = href.replace('https://www.monacor.de', base + '/de');
    href += 'index.html';

    $(this).attr('href', href);
  });
}

// scroll on page-load, when hash is present
if (document.location.hash) {
  var value,
      hash = document.location.hash;
  if (hash.match(/^#mcfg_/)) {
    value = 'productselector-type-' + hash.split('#mcfg_')[1];
    $('button[value="' + value + '"]').click();
    document.location.hash = '';
  }
}

var loading = $('#content').find('.productselector-loading');

if (loading.length) {
  console.log('konfigurator loading');

  window.setTimeout(function () {
    loading.fadeOut('fast', function () {
      $(this).hide();
    });
  }, 1000);
}

},{}],26:[function(require,module,exports){
'use strict';

var lang = $.trim($('.lang-name').text()).match(/^Deutsch/) ? 'de' : 'en';
var configurators = $('.productselector');
var data = window.productselector;

function isValidEmail(email) {
  return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  );
}
function isMobileUI() {
  return $(window).width() < 768;
}

// restart button
$('#mainnav').find('.navbar-header').prepend(function () {
  var button = $(document.createElement('button'));
  button.prop('type', 'button');
  button.addClass('btn btn-default pull-right productselector-backbtn restart-steps hidden');

  button.on('click', function () {
    configurators.trigger('reset');
  });

  if (lang === 'de') {
    button.append("<i class=\"fa fa-refresh\"></i> <span class='hidden-xs hidden-sm'>Neue Konfiguration</span>");
    button.attr('title', 'Konfigurator');
  } else {
    button.append("<i class=\"fa fa-refresh\"></i> <span class='hidden-xs hidden-sm'>Restart Configurator</span>");
    button.attr('title', 'Configurator');
  }

  return button;
});

// configurator element
configurators.each(function () {
  var selector = $(this);
  var currentstep = 1;
  var process = {};
  var country = selector.data('country');

  /**
   * open popup for given article number
   * @param {string} artnr
   * @param {number} stepnum
   */
  function showRequirementPopup(artnr, stepnum) {
    var popup = selector.find('article.product-required[data-artnr="' + artnr + '"]');
    if (popup.size()) {

      selector.find('article.product-required').addClass('hidden').data('number', stepnum).data('mode', 'products');
      selector.find('.productselector-modal-bg').removeClass('hidden');
      popup.removeClass('hidden');

      popup.find('.product-required-footer .required-remove').unbind('click').bind('click', function () {

        selector.find('.productselector-modal-bg').addClass('hidden');

        popup.addClass('hidden');

        var products = selector.find('.product[data-artnr="' + artnr + '"]');
        products.each(function () {
          $(this).find('.amount-text').val(0);
        });

        _.each(process, function (submit, submit_stepnum) {
          _.each(submit.value, function (item, submit_artnr) {
            if (submit_artnr == artnr) {
              delete process[submit_stepnum].value[submit_artnr];
            }
          });
        });
        render();

        return false;
      });

      return true;
    }
    return false;
  }

  /**
   * check for any open requirement-popups
   * read all inputs from the offered products and gather a total-amount
   * - enable "close" button if at least one item has amount > 0
   * - disable if not
   */
  function toggleRequirementCloseButton() {
    selector.find('.product-required:not(.hidden)').each(function () {
      var openRequirement = $(this),
          numChosen = 0;
      openRequirement.find('.amount-text').each(function () {
        numChosen += parseInt($(this).val(), 10);
      });

      if (numChosen > 0) {
        openRequirement.find('.product-required-footer .required-accept').removeClass('disabled').removeAttr('disabled');
      } else {
        openRequirement.find('.product-required-footer .required-accept').addClass('disabled').attr('disabled', 'disabled');
      }
    });
  }

  /**
   * Walk through current process items and check if any
   * product has a filled "needs" value. Then open the requirement
   * popup for this article number
   */
  function findProductsInNeed() {
    _.each(process, function (submit, stepnum) {
      var step = data.steps[stepnum];
      if (step.mode === 'products') {
        _.each(submit.value, function (item, artnr) {
          if (item.needs) {
            showRequirementPopup(artnr, stepnum);
          }
        });
      }
    });
    toggleRequirementCloseButton();
  }

  // ------------------------------------------------------------------------------

  // counting

  function updateTabAmounts() {
    selector.find('.step').each(function () {
      var step = $(this);
      step.find('.step-tabs li').each(function () {
        var tab = $(this),
            total = 0;
        var badge = tab.find('.step-tab-amt');
        var grouptag = tab.data('tag');
        step.find('.step-products[data-tag="' + grouptag + '"] .amount-text').each(function () {
          var amountInput = $(this),
              amount = parseInt(amountInput.val(), 10);
          if (amount > 0) {
            total += amount;
          }
        });

        if (total > 0) {
          badge.addClass('active');
        } else {
          badge.removeClass('active');
          total = 0;
        }
        badge.text(total);
      });
    });
  }

  // mirror input value to other inputs of the same product
  function mirrorValue(product, value) {
    selector.find('.product[data-artnr="' + product.data('artnr') + '"]').not(product).each(function () {
      $(this).find('.amount-text').val(value);
    });
  }

  // ------------------------------------------------------------------------------

  // sliding

  /**
   * @param {Number} stepnum
   * @returns {void}
   */
  function slideNext(stepnum) {
    if (isMobileUI()) {
      if (stepnum === $('.step').size()) {
        return false;
      }
      selector.find('.steps').css({
        'margin-left': '-' + stepnum * 100 + 'vw'
      });
      selector.find('.step-body').removeClass('show-tabcontent');
      return true;
    }
  }

  /**
   * @param {Number} stepnum
   * @returns {void}
   */
  function slidePrev(stepnum) {
    if (isMobileUI()) {
      if (stepnum <= 0) {
        return false;
      }
      selector.find('.steps').css({
        'margin-left': '-' + (stepnum - 2) * 100 + 'vw'
      });
      selector.find('.step-body').removeClass('show-tabcontent');
      return true;
    }
  }

  // ------------------------------------------------------------------------------

  // rendering

  /**
   * generate blacklist from process stack
   * @returns {Array}
   */
  function getCurrentBlacklist() {
    var blacklist = [];

    _.each(process, function (submit, stepnum) {
      if (submit.value !== 'skipped') {
        if (submit.type === 'buttons') {
          blacklist = _.union(blacklist, data.groups[submit.value].blacklist);
        }
        if (submit.type === 'products') {
          _.each(submit.value, function (item, artnr) {
            if (typeof data.articles[artnr] !== 'undefined') {
              var product_config = data.articles[artnr];
              blacklist = _.union(blacklist, product_config.blacklist);
            }
          });
        }
      }
    });

    // remove ineglible products from current process
    _.each(process, function (submit, stepnum) {
      if (submit.value !== 'skipped') {
        if (submit.type === 'products') {

          // reset inputs and remove article from products array if found on blacklist
          _.each(submit.value, function (item, artnr) {
            if (_(blacklist).contains(artnr)) {
              selector.find('.product[data-artnr="' + artnr + '"]').each(function () {
                $(this).find('.amount-text').val(0);
                $(this).find('.amount-range input').val(0).change();
              });
              delete process[stepnum].value[artnr];
            }
          });

          // remove entire step from process if it ends up "empty"
          if (_.isEmpty(submit.value)) {
            delete process[stepnum];
          }
        }
      }
    });

    return blacklist;
  }

  /**
   *
   * @returns {Array}
   */
  function getCurrentArtNr() {
    var artnrs = [];
    _.each(process, function (submit) {
      if (submit.value !== 'skipped') {
        if (submit.type === 'products') {
          _.each(submit.value, function (item, artnr) {
            artnrs.push(artnr);
          });
        }
      }
    });
    return artnrs;
  }

  /**
   * Check if there are any problems within the current selection
   * by comparing each product requirement config
   *
   * @returns {number}
   */
  function hasUnmetDependencies() {
    var currentArtnrs = getCurrentArtNr();
    var unmetDependencies = 0;

    // remove ineglible products from current process
    _.each(process, function (submit, stepnum) {
      if (submit.value !== 'skipped') {
        if (submit.type === 'products') {
          _.each(submit.value, function (item, artnr) {
            // check if this article has a dedicated config
            if (typeof data.articles[artnr] !== 'undefined') {
              var requires = data.articles[artnr].requires;

              // check if this products has special requirements
              if (requires.length) {

                var matchingConstraints = 0;

                // look if the required arnr is ANYWHERE in the cart
                _.each(requires, function (requiredArtnr) {
                  if (_.contains(currentArtnrs, requiredArtnr)) {
                    matchingConstraints++;
                  }
                });

                // if non of the required was found in the config
                // assign the entire "requires" list to the cart-item
                // so that we can build a "suggest" list for it.
                if (matchingConstraints === 0) {
                  process[stepnum].value[artnr].needs = requires;
                } else {
                  // just in case: reset to default if dep is met
                  process[stepnum].value[artnr].needs = false;

                  // increase total error counter
                  unmetDependencies++;
                }
              }
            }
          });
        }
      }
    });

    return unmetDependencies;
  }

  /**
   * Render Cart Modal content
   */
  function renderCartOverlay() {
    var modal = selector.find('.cart-modal'),
        items = modal.find('.cart-items'),
        itemlist = '',
        cartTotal = 0,
        priceTotal = 0,
        unmetDeps = hasUnmetDependencies(),
        langTooltipRequires = modal.data('l10n-tooltip');

    _.each(process, function (submit, stepnum) {
      var step = data.steps[stepnum];
      itemlist += '<div class="items-step">';
      itemlist += '<div class="items-step-title">';
      itemlist += '<a href="#edit" data-step="' + stepnum + '" class="pull-right goto-step"><i class="fa fa-edit"></i></a>';
      itemlist += '<span>' + stepnum + '. ' + step.title + '</span>';
      itemlist += '</div>';
      itemlist += '<div class="items-step-body">';
      if (submit.value === 'skipped') {
        itemlist += '<div class="items-step-item group-item">-</div>';
      } else {
        if (submit.type === 'buttons') {
          var group = data.groups[submit.value];
          itemlist += '<div class="items-step-item group-item">' + group.name + '</div>';
        }
        if (submit.type === 'products') {
          _.each(submit.value, function (item, artnr) {
            var product = data.products[artnr];
            var price = Math.round(item.amount * parseFloat(product.price)).toFixed(2);

            var t = item.needs ? langTooltipRequires + '<br>' + item.needs.join(', ') : '';

            itemlist += '<div class="items-step-item prod-item' + (item.needs ? ' prod-unmet' : '') + '" title="' + t + '">';
            itemlist += '<span class="amt">' + item.amount + ' x</span>';
            itemlist += '<span class="name">' + product.artnr + '</span>';

            if (country === 'CH') {
              itemlist += '<span class="price pull-right">Auf Anfrage</span>';
            } else {
              itemlist += '<span class="price pull-right">&euro; ' + price + '</span>';
            }

            itemlist += '</div>';

            cartTotal += item.amount;
            priceTotal += item.amount * parseFloat(product.price);
          });
        }
      }
      itemlist += '</div>';
      itemlist += '</div>';
    });

    if (_(process).size()) {
      modal.find('.cart-empty').addClass('hidden');
    } else {
      modal.find('.cart-empty').removeClass('hidden');
    }

    var priceTotalRound = Math.round(priceTotal).toFixed(2);

    selector.find('.cart-handle-counter').text(cartTotal);

    if (country === 'CH') {
      selector.find('.cart-total-price').html('Auf Anfrage');
    } else {
      selector.find('.cart-total-price').html('&euro; ' + priceTotalRound + '');
    }

    items.html(itemlist);

    if (!isMobileUI()) {
      $('.items-step-item').tooltip({ html: true, placement: 'left' });
    }
  }

  /**
   * Render UI
   * @returns {void}
   */
  function render() {
    var blacklist = getCurrentBlacklist();

    // hide ineglible products
    selector.find('.product').each(function () {
      var product = $(this);

      // hide each product on the current blacklist
      if (_(blacklist).contains(product.data('artnr'))) {
        product.addClass('hidden');
      } else {
        product.removeClass('hidden');
      }
    });

    // hide empty product tabs
    selector.find('.step-products').each(function () {
      var tabpane = $(this),
          stepbody = tabpane.parents('.step-body');
      var tab = stepbody.find('li[data-tag="' + tabpane.data('tag') + '"]');

      // hide tab and content if ALL contained products are invisible
      if (tabpane.find('.product').size() === tabpane.find('.product.hidden').size()) {
        tabpane.addClass('hidden');
        tab.addClass('hidden');
      } else {
        // restore
        tabpane.removeClass('hidden');
        tab.removeClass('hidden');
      }
    });

    // find highest completed step
    var completed = 1;
    if (_.size(process)) {
      // process not empty
      for (var s = 0; s < _.size(process); s++) {
        if (typeof process[s + 1] === 'undefined') {
          break; // stop counting up
        }
        completed = parseInt(process[s + 1].step, 10);
      }
      completed++;
    }

    // hide all steps by default
    selector.find('.step').removeClass('completed').removeClass('active').addClass('hidden');

    // iterate up to current-step and show all steps up that number
    for (var c = 1; c < completed; c++) {
      selector.find('.step[data-number="' + c + '"]').addClass('completed');
    }
    for (var i = 1; i <= completed; i++) {
      selector.find('.step[data-number="' + i + '"]').removeClass('hidden');
    }

    // display the next step
    var nextStep = selector.find('.step[data-number="' + completed + '"]');

    // flag as current tab
    nextStep.addClass('active');

    // check if this is a products tab
    if (nextStep.hasClass('step-mode-products')) {

      // remove any previous compatibility warning
      nextStep.find('.step-body .step-incompat').remove();

      // check if all tabs are hidden
      if (!nextStep.find('.step-tabs li:not(.hidden)').size()) {
        if (lang === 'de') {
          nextStep.find('.step-body').append('<p class="step-incompat alert alert-danger">Keine kompatiblen Produkte verfügbar.</p>');
        } else {
          nextStep.find('.step-body').append('<p class="step-incompat alert alert-danger">No compatible products available.</p>');
        }
      }
    }

    // if there is only 1 product-tab on the entire visible step, activate it.
    selector.find('.step:not(.hidden)').each(function () {
      var visibleTabs = $(this).find('.step-tabs li:not(.hidden)');
      if (visibleTabs.size() === 1) {
        visibleTabs.find('a').click();
      }
    });

    updateTabAmounts();
    renderCartOverlay();
    findProductsInNeed();
  }

  selector.on('render', function () {
    render();
  });

  selector.on('reset', function () {
    currentstep = 1;
    process = {};
    selector.find('.amount-text').val(0);
    render();
  });

  // ------------------------------------------------------------------------------

  /**
   * Responsive Active-Tab Workaround
   */
  $(window).on('resize', function () {
    if (isMobileUI()) {
      selector.find('.step-tabs li').removeClass('active');
    }
  });

  /**
   * Sticky Cart Handle
   */
  var modalHandle = selector.find('.cart-modal-handle');
  $(window).on('scroll', function () {
    var scrollTop = $(window).scrollTop();
    if (scrollTop >= 62) {
      modalHandle.css('top', scrollTop + 'px');
    } else {
      modalHandle.css('top', '53px');
    }
  });

  // ------------------------------------------------------------------------------
  // overlay and display control

  selector.on('click', '.productselector-type-select button', function () {
    var next_tab = selector.find('.' + $(this).val()),
        offset = next_tab.offset().top;

    // switch active button highglight
    selector.find('.productselector-type-select button').removeClass('active');
    $(this).addClass('active');

    // show tab element, hide any other
    selector.find('.productselector-type').addClass('hidden');
    next_tab.removeClass('hidden');

    // hack: hide optional introtext for steps
    if ($(this).val() === 'productselector-type-steps') {
      selector.find('.step-introtext').removeClass('hidden');
      selector.find('.cart-modal-handle').removeClass('hidden');
      $('button.restart-steps').removeClass('hidden');
    } else {
      selector.find('.step-introtext').addClass('hidden');
      selector.find('.cart-modal-handle').addClass('hidden');
      $('button.restart-steps').addClass('hidden');
    }

    // scroll to top
    if (isMobileUI()) {
      next_tab.stop().animate({
        'scrollTop': offset - 60
      }, 400);
    } else {
      $('html, body').stop().animate({
        'scrollTop': 0
      }, 400);
    }
  });

  // ------------------------------------------------------------------------------
  // cart modal

  selector.on('click', '.cart-modal-handle', function () {
    var modal = selector.find('.cart-modal');
    modal.addClass('active');
    return false;
  });

  selector.on('click', '.cart-button', function () {
    var modal = selector.find('.cart-modal');
    modal.addClass('active');
    return false;
  });

  selector.on('click', '.cart-modal-close .btn', function () {
    var modal = $(this).parents('.cart-modal');
    modal.removeClass('active');
    return false;
  });

  // ------------------------------------------------------------------------------
  // product detail window opener

  selector.on('click', '.product-open', function () {
    var modal = $('#' + $(this).data('target'));
    modal.removeClass('hidden');
    selector.find('.productselector-modal-bg').removeClass('hidden');

    if (!modal.hasClass('initialized')) {
      var carousel = modal.find(".carousel");
      if (carousel.size()) {
        carousel.find('.product-figure').each(function () {
          var hammertime = new Hammer(this);
          hammertime.on('swipeleft', function (ev) {
            carousel.carousel('next');
          });
          hammertime.on('swiperight', function (ev) {
            carousel.carousel('prev');
          });
        });
      }
      modal.addClass('initialized');
    }
    return false;
  });

  // ------------------------------------------------------------------------------
  // hide product detail view on button click

  selector.on('click', '.productselector-modal-close .btn', function () {
    var modal = $(this).parents('.productselector-modal');
    modal.addClass('hidden');
    selector.find('.productselector-modal-bg').addClass('hidden');
    return false;
  });

  // ------------------------------------------------------------------------------
  // prevent action on product image click
  selector.on('click', '.product-img', function () {
    return false;
  });

  // ------------------------------------------------------------------------------
  // amount controls

  // decrement
  selector.on('click', '.product-amt-decr', function () {
    var button = $(this);
    var product = button.parents('.product');
    var amount = product.find('.amount-text');
    var current = parseInt(amount.val(), 10);

    if (current > 0) {
      amount.val(current - 1).change();

      mirrorValue(product, current - 1);
      updateTabAmounts();
    }

    return false;
  });

  // increment
  selector.on('click', '.product-amt-incr', function () {
    var button = $(this);
    var product = button.parents('.product');
    var amount = product.find('.amount-text');
    var current = parseInt(amount.val(), 10);

    amount.val(current + 1).change();

    mirrorValue(product, current + 1);
    updateTabAmounts();

    return false;
  });

  // parse manual input and trigger events
  selector.on('keyup', '.amount-text', function () {
    var amount = $(this);
    var product = amount.parents('.product');
    var current = amount.val();

    if (current.length > 0) {

      // strip any bad characters
      current = current.replace(/\D/g, '');

      // dont allow negatives
      if (parseInt(current, 10) < 0) {
        current = '0';
      }

      // trigger change event
      amount.val(current).change();

      mirrorValue(product, current);
      updateTabAmounts();
    }
  });

  // ------------------------------------------------------------------------------
  // mobile nav: tabs

  selector.find('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var tab = $(e.target),
        tabInstance = tab.data('bs.tab');
    var li = tab.parents('li'),
        groupTag = li.data('tag');
    var body = tab.parents('.step-body');

    body.addClass('show-tabcontent');

    if (isMobileUI()) {
      window.setTimeout(function () {
        selector.find('.step-tabs li').removeClass('active');
      }, 400);
    }
  });

  selector.find('.step-tab-close').on('click', function () {
    var link = $(this),
        body = link.parents('.step-body');
    body.removeClass('show-tabcontent');
    return false;
  });

  // ------------------------------------------------------------------------------

  // user answers a button-step

  selector.on('click', '.step-answer', function () {
    var button = $(this);
    var step = button.parents('.step');
    var stepnum = parseInt(step.data('number'), 10);
    var grouptag = button.val();

    currentstep = stepnum;

    step.find('.btn').removeClass('active');
    button.addClass('active');

    // commit to global process hash
    process[stepnum] = {
      step: stepnum,
      type: step.data('mode'),
      value: grouptag
    };

    render();
    slideNext(stepnum);
    return false;
  });

  selector.on('click', '.step-skip', function () {
    var button = $(this);
    var step = button.parents('.step');
    var stepnum = parseInt(step.data('number'), 10);

    // commit to global process hash
    process[stepnum] = {
      step: stepnum,
      type: step.data('mode'),
      value: 'skipped'
    };

    render();
    slideNext(stepnum);
    return false;
  });

  // ------------------------------------------------------------------------------

  // user changes amounts on a product

  selector.on('change', '.amount-text', function () {
    var input = $(this),
        value = {};
    var step = $(this).parents('.step');

    if (!step.size()) {
      step = $(this).parents('.product-required');
    }

    var stepnum = parseInt(step.data('number'), 10);
    var amount = parseInt(input.val(), 10);
    var artnr = input.data('artnr');

    // load previous value
    if (process[stepnum]) {
      value = process[stepnum].value;
      if (value === 'skipped') {
        value = {};
      }
    }

    // add to value hash if amount is positive
    if (amount > 0) {
      value[artnr] = {
        artnr: artnr,
        amount: amount,
        needs: false
      };
    } else {
      // check if reference is present in current value and remove
      if (typeof value[artnr] !== 'undefined') {
        delete value[artnr];
      }
    }

    // commit to global process hash
    process[stepnum] = {
      step: stepnum,
      type: step.data('mode'),
      value: value
    };

    if (_.isEmpty(value)) {
      delete process[stepnum];
    }

    render();
  });

  // ------------------------------------------------------------------------------
  // mobile nav


  // step navigation: swipe
  selector.find('.step').each(function () {
    var stepnum = parseInt($(this).data('number'), 10);
    var hammertime = new Hammer(this);
    hammertime.on('swipeleft', function () {
      slideNext(stepnum);
    });
    hammertime.on('swiperight', function () {
      slidePrev(stepnum);
    });
  });

  // step navigation: swipe on content
  selector.find('.step-content').each(function () {
    var stepnum = parseInt($(this).parents('.step').data('number'), 10);
    var hammertime = new Hammer(this);
    hammertime.on('swipeleft', function () {
      slideNext(stepnum);
    });
    hammertime.on('swiperight', function () {
      slidePrev(stepnum);
    });
  });

  // step navigation: next
  selector.on('click', '.step-nav-next', function () {
    var stepnum = parseInt($(this).parents('.step').data('number'), 10);
    slideNext(stepnum);
    return false;
  });

  // step navigation: prev
  selector.on('click', '.step-nav-prev', function () {
    var stepnum = parseInt($(this).parents('.step').data('number'), 10);
    slidePrev(stepnum);
    return false;
  });

  // step navigation: next
  selector.on('click', '.step-number-next', function () {
    var stepnum = parseInt($(this).text(), 10) - 1;
    slideNext(stepnum);
    return false;
  });

  // step navigation: prev
  selector.on('click', '.step-number-prev', function () {
    var stepnum = parseInt($(this).text(), 10) + 1;
    slidePrev(stepnum);
    return false;
  });

  // scroll to step (edit icon)
  selector.on('click', '.goto-step', function () {
    var stepnum = parseInt($(this).data('step'), 10);
    if (isMobileUI()) {
      var modal = $(this).parents('.cart-modal');
      modal.addClass('hidden');
      slideNext(stepnum - 1);
    } else {
      selector.find('.step').removeClass('active');
      var target = selector.find('.step[data-number="' + stepnum + '"]');
      target.addClass('active');
      $.smoothScroll({
        scrollTarget: target,
        offset: -150
      });
    }
    return false;
  });

  // ------------------------------------------------------------------------------
  // email validation
  var emailField = selector.find('input.cart-email');

  emailField.on('keydown', function (e) {
    if (e.which === 32) // no spaces
      return false;
  });
  emailField.on('change', function () {
    $(this).val($(this).val().replace(/\s/gm, ''));
    var emailFieldWrap = $(this).parents('.form-group');
    if (!isValidEmail($(this).val())) {
      emailFieldWrap.addClass('has-error');
    } else {
      emailFieldWrap.removeClass('has-error');
    }
  });

  // ------------------------------------------------------------------------------
  // form submit

  var form = selector.find('.finalize-form');

  function isEnduser() {
    return selector.find('#userselect input:checked').val() === 'private';
  }

  var captchaInput = form.find('.captcha-input');
  captchaInput.realperson({
    length: 6,
    regenerate: form.find('.captcha-regen').data('value')
  });

  selector.on('change', '#userselect input', function () {
    if (!isEnduser()) {
      $('.finalize-enduser').addClass('hidden');
      $('.finalize-client').removeClass('hidden');
      $('.finalize-form-fields').removeClass('hidden');
      //form.find('input[name="kdnr"]').attr('required','required');
      form.find('input[name="telefon"]').attr('required', 'required');
      form.find('input[name="company"]').attr('required', 'required');
      form.find('input[name="name"]').attr('required', 'required');
      form.find('input[name="email"]').attr('required', 'required');
      form.find('input[name="read_privacy"]').attr('required', 'required');
    } else {
      $('.finalize-client').addClass('hidden');
      $('.finalize-enduser').removeClass('hidden');
      $('.finalize-form-fields').addClass('hidden');
      //form.find('input[name="kdnr"]').removeAttr('required');
      form.find('input[name="telefon"]').removeAttr('required');
      form.find('input[name="company"]').removeAttr('required');
      form.find('input[name="name"]').removeAttr('required');
      form.find('input[name="email"]').removeAttr('required');
      form.find('input[name="read_privacy"]').removeAttr('required');
    }
  });

  selector.on('change', 'select[name="country"]', function () {
    var option = $(this).find('option').get(this.selectedIndex);
    $('#cn').text($(option).data('cn'));
  });

  selector.on('submit', '.finalize-form', function () {
    var formdata = { steps: [], lang: lang },
        valid = false;

    if (!isEnduser()) {
      if (!isValidEmail(emailField.val())) {
        form.find('input[type=email]').focus();
        valid = false;
      } else {
        valid = true;
      }
    } else {
      valid = true;
    }

    if (valid) {
      // make data hash
      _.each(form.serializeArray(), function (input) {
        formdata[input.name] = input.value;
      });
      formdata['telprfx'] = $('#cn').text();

      // add items to hash
      _.each(process, function (submit, stepnum) {

        var step = {
          number: data.steps[stepnum].number,
          title: data.steps[stepnum].title,
          type: submit.type,
          group: '',
          products: [],
          skipped: false
        };

        if (submit.type === 'buttons') {
          step.group = data.groups[submit.value].name;
        }

        if (submit.type === 'products') {
          if (submit.value !== 'skipped') {
            _.each(submit.value, function (item, artnr) {
              step.products.push(item);
            });
          } else {
            step.skipped = true;
          }
        }

        formdata.steps.push(step);
      });

      if (document.location.href.match(/\/de\/|\/en\//)) {
        // Electron Mode

        if (lang === 'de') {
          alert("Vielen Dank!\nWir werden Sie so bald wie möglich kontaktieren.");
        } else {
          alert("Thank You!\nWe will contact you as soon as possible.");
        }
        $('#app').trigger('formsubmit', formdata);

        selector.trigger('reset');
      } else {
        // Web Mode

        $.post(document.location.pathname, { prodconf: formdata }, function (resp) {
          if (resp.success) {

            if (!resp.pdf) {
              // empty form
              form.find('input[name="company"]').val('');
              form.find('input[name="name"]').val('');
              form.find('input[name="email"]').val('');
              form.find('input[name="telefon"]').val('');
              form.find('input[name="read_privacy"]').prop('checked', false);
              form.find('input[name="read_privacy_2"]').prop('checked', false);

              // say thanks
              if (lang === 'de') {
                alert("Vielen Dank.\nSie sollten in Kürze eine E-Mail erhalten.");
              } else {
                alert("Thank You!\nYou should receive an e-mail from us shortly.");
              }

              selector.trigger('reset');
            } else {
              window.location.href = resp.pdf;
            }
          } else {
            console.log('error:', resp.message);
          }
        });
      }
    }
    return false;
  });
});

},{}],27:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

$(function () {
  var bodyClassNames = $('body').prop('className');

  if (bodyClassNames.match(/szenario-/)) {
    require('./_scenario.js');
  }

  if (bodyClassNames.match(/konfigurator/)) {
    require('./_scenarios.js');
    require('./_steps.js');
  }

  if (bodyClassNames.match(/szenario-/) || bodyClassNames.match(/konfigurator/)) {
    require('./_shared.js');
  }
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./_scenario.js":23,"./_scenarios.js":24,"./_shared.js":25,"./_steps.js":26}],28:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var ContactForm = {
  form: null,
  wrap: null,
  initialize: function initialize() {
    this.wrap = $('.contactform');
    if (this.wrap.size()) {

      this.form = this.wrap.find('form');
      this.step1 = this.wrap.find('.step1');
      this.step2 = this.wrap.find('.step2');
      this.submit = this.wrap.find('.contactform-submit');
      var thanks = $('#contact_submit_thanks');

      this.wrap.on('click', '.contactform-show', $.proxy(function () {
        this.step1.hide();
        this.step2.show();
      }, this));

      this.wrap.on('click', '.contactform-cancel', $.proxy(function () {
        this.step2.hide();
        this.step1.show();
      }, this));

      this.wrap.on('click', '.contactform-close', $.proxy(function () {
        this.wrap.find('.contactform-cancel').click();
      }, this));

      thanks.on('click', $.proxy(function () {
        this.step2.hide();
        this.step1.show();
        thanks.hide();
      }, this));

      this.form.on('submit', $.proxy(function (e) {
        var submit = this.submit;

        if (this.unlocked && !this.wrap.find('.at-error').size()) {
          $.post(this.form.attr('action'), this.form.serialize(), function () {
            if ($(window).width() <= 768) {
              var message = $.trim(thanks.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
              alert(message);
            } else {
              thanks.show();
            }
            submit.text(submit.data('text-default'));
          });
        }

        return false;
      }, this));

      // captcha lock
      this.unlocked = false;
      this.submit.attr('disabled', 'disabled').prop('disabled', true);
      window.contactUnlock = $.proxy(function () {
        this.submit.removeAttr('disabled').prop('disabled', false);
        this.unlocked = true;
      }, this);
    }
  }
};

module.exports = ContactForm;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],29:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var DownloadSearch = {
    form: null,
    cache: {},
    timeout: null,
    lastQuery: '',
    templates: {},
    resultsMobile: null,
    resultsDesktop: null,
    initialize: function initialize() {
        this.icon = '<img src="' + App.element.data('icon-dl') + '" class="dl-icon"> ';
        this.iconPDF = '<img src="' + App.element.data('icon-pdf') + '" class="dl-icon"> ';

        this.form = $('#download-search');
        if (this.form.size()) {
            this.resultsMobile = $('#search-results-mobile');
            this.resultsDesktop = $('#search-results-desktop');
            this.noResults = $('#downloads-error');
            this.templates = {
                mobile: $('#download-mobile').html(),
                header: $('#download-header').html(),
                desktop: $('#download-desktop').html()
            };
            this.form.on('submit', $.proxy(function () {
                var value = this.getValue();
                if (this.isValidQuery(value)) {
                    this.executeSearch(value);
                }
                return false;
            }, this));
            this.input = this.form.find('input.query');
            this.input.on('keyup', $.proxy(function () {
                window.clearTimeout(this.timeout);
                this.timeout = window.setTimeout($.proxy(function () {
                    var value = this.getValue();
                    if (this.isValidQuery(value)) {
                        this.executeSearch(value);
                    }
                }, this), 500);
            }, this));
        }
    },
    isValidQuery: function isValidQuery(value) {
        return value.length >= 2 && value != this.lastQuery;
    },
    hasCache: function hasCache(value) {
        if (!value.length) return false;
        return typeof this.cache[value] !== 'undefined';
    },
    getValue: function getValue() {
        return $.trim(this.input.val());
    },
    executeSearch: function executeSearch(value) {
        if (!value) value = this.getValue();
        var query = encodeURIComponent(value);
        this.noResults.addClass('hidden');

        if (this.hasCache(value)) {
            this.renderResults(this.cache[value]);
        } else {
            $.getJSON(this.form.attr('action') + '=' + query + '&r=json', $.proxy(function (result) {
                this.cache[value] = result;
                this.renderResults(result);
            }, this));
        }
        this.lastQuery = value;
    },
    basename: function basename(fp, truncate) {
        var path, filename;

        if (!truncate) truncate = 0;
        if (!fp.length) return '';

        if (fp.indexOf('%2F') !== -1) {
            path = fp.split('%2F');
            filename = path[path.length - 1];
        } else {
            path = fp.split('/');
            filename = path[path.length - 1];
        }

        if (truncate && filename.length >= truncate) {
            return filename.slice(0, truncate - 5) + ' ...';
        }
        return filename;
    },
    renderResults: function renderResults(data) {
        this.resultsMobile.empty();
        this.resultsDesktop.empty();

        if (data.found) {
            this.resultsDesktop.append($(this.templates.header));
            $.each(data.results, $.proxy(this.renderRecord, this));
            this.resultsDesktop.find('a').tooltip();
        } else {
            this.noResults.removeClass('hidden');
        }
    },
    renderRecord: function renderRecord(i, row) {
        var name = row.subheader.length ? row.subheader : row.header;

        // mobile
        var mobileRow = $(this.templates.mobile);
        var mobileFiles = mobileRow.find('.files');
        mobileRow.find('.name').text(name);
        if (row.app.length) mobileFiles.append('<li>' + this.icon + '<a href="' + row.app + '" target="_blank">' + this.basename(row.app) + '</a></li>');
        if (row.software.length) mobileFiles.append('<li>' + this.icon + '<a href="' + row.software + '" target="_blank">' + this.basename(row.software) + '</a></li>');
        if (row.firmware.length) mobileFiles.append('<li>' + this.icon + '<a href="' + row.firmware + '" target="_blank">' + this.basename(row.firmware) + '</a></li>');
        if (row.manual.length) mobileFiles.append('<li>' + this.iconPDF + '<a href="' + row.manual + '" target="_blank">' + this.basename(row.manual) + '</a></li>');
        if (row.info.length) mobileFiles.append('<li>' + this.iconPDF + '<a href="' + row.info + '" target="_blank">' + this.basename(row.info) + '</a></li>');
        this.resultsMobile.append(mobileRow);

        // desktop
        var desktopRow = $(this.templates.desktop);
        desktopRow.find('.name').text(name);
        if (row.app.length) desktopRow.find('.file-app').append(this.icon + '<a href="' + row.app + '" title="' + this.basename(row.app) + '" target="_blank">' + this.basename(row.app, 25) + '</a>');
        if (row.software.length) desktopRow.find('.file-software').append(this.icon + '<a href="' + row.software + '" title="' + this.basename(row.software) + '" target="_blank">' + this.basename(row.software, 25) + '</a>');
        if (row.firmware.length) desktopRow.find('.file-firmware').append(this.icon + '<a href="' + row.firmware + '" title="' + this.basename(row.firmware) + '" target="_blank">' + this.basename(row.firmware, 25) + '</a>');
        if (row.manual.length) desktopRow.find('.file-manual').append(this.iconPDF + '<a href="' + row.manual + '"  title="' + this.basename(row.manual) + '"target="_blank">' + this.basename(row.manual, 25) + '</a>');
        if (row.info.length) desktopRow.find('.file-info').append(this.iconPDF + '<a href="' + row.info + '" title="' + this.basename(row.info) + '" target="_blank">' + this.basename(row.info, 25) + '</a>');
        this.resultsDesktop.append(desktopRow);
    }
};

module.exports = DownloadSearch;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],30:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var Retailer = {
  // json
  data: null,

  // objects
  gmap: null,
  geocoder: null,
  center: null,
  circle: null,
  active: null,

  // elements
  map: null,
  form: null,
  country: null,
  address: null,
  fillcolor: null,
  results: null,
  overlay: null,
  resultItems: null,

  // templates
  resultTpl: '',

  // search
  markers: [],
  kmRadius: null,

  /**
   * Start
   */
  initialize: function initialize() {

    if (!$('#haendlersuche.facherrichter').size()) {
      return false;
    }

    this.form = $('#map_form');
    if (!this.form.size()) {
      return false;
    }

    if (typeof google === "undefined") {
      throw Error('Required google global not loaded. Is maps api loaded?');
    }
    if (typeof window._retailers === 'undefined') {
      throw Error('Required map data not found in source.');
    }
    this.overloadGoogleMap();

    this.data = window._retailers;

    this.map = $('#map');
    this.country = $('#map_country');
    this.kmRadius = $('#map_radius');
    this.emptyMessage = $('#map_result_empty');
    this.category = $('#map_productcategory'); // select
    this.address = $('#map_address');
    this.fillcolor = $('#map_color');
    this.overlay = $('#store');
    this.results = $('#map_results');
    this.resultItems = $('#map_result_items');
    this.resultTpl = $('#map_result_tpl').html();

    this.generateMarkers();
    this.geocoder = new google.maps.Geocoder();

    this.overlay.hide();

    if (this.map.length) {
      this.gmap = new google.maps.Map(this.map.get(0), {
        center: { lat: 51.165691, lng: 10.451526000000058 },
        zoom: 8,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: true
      });
    } else {
      this.gmap = null;
      console.log('no-map mode');
    }

    // listen
    this.form.on('submit', $.proxy(this.onFormSubmit, this));
    this.category.on('change', $.proxy(this.onFormSubmit, this));
    $(window).on('resize', $.proxy(this.onWindowResize, this));
    this.form.on('reset', $.proxy(function () {
      this.resultItems.empty();
      this.emptyMessage.hide();
      var marker = void 0;
      for (var i = 0; i < this.markers.length; i++) {
        marker = this.markers[i];
        this.addResult(marker);
      }
      this.results.show();
      this.overlay.hide();

      var self = this;
      window.setTimeout(function () {
        self.equalizeResults();
      }, 50);
    }, this));

    // map overlay actions, unless map is disabled
    if (this.overlay.length) {
      this.overlay.find('.actions .btn').on('click', $.proxy(function () {
        var address = '';
        this.overlay.find('address .data-field').each(function () {
          if ($(this).hasClass('country')) {
            // we only have GERMANY for now..
            // remove check if we expand to other countries and this info becomes relevant.
            address += '\n'; // extra linebreak after country, intro to contact.
          } else {
            address += $(this).text() + ($(this).hasClass('zip') ? ' ' : '\n');
          }
        });
        $('#merkliste_merch').val(address);
        $('#header').find('.toggle-favorites').first().click();
        $('#merkliste').find('.show-form').first().click();
      }, this));
    }

    var urlCategory = '';
    var _self = this;
    if ((location.hash + '').length) {
      urlCategory = location.hash.substr(1);
      this.category.find('option').each(function (i, option) {
        if (option.value === urlCategory) {
          _self.category.prop('selectedIndex', i);
          $('#map_form').submit();
        }
      });
    }

    return this;
  },

  generateMarkers: function generateMarkers() {
    var _self = this;
    this.markers = [];
    var retailer, latLng, marker;
    var image = {
      size: new google.maps.Size(44, 52),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(22, 52),
      url: '/design/img/stageline/map-pin-grey.png'
    };
    for (var i = 0; i < this.data.length; i++) {
      retailer = this.data[i];
      latLng = new google.maps.LatLng(retailer.lat, retailer.lng);

      marker = new google.maps.Marker({
        position: latLng,
        //icon: image,
        //shape: {
        //  coords: [22, 26, 25],
        //  type: 'circle'
        //},
        draggable: false,
        raiseOnDrage: false
      });
      marker.retailer = retailer;

      this.markers.push(marker);
      this.addResult(marker);
    }
    this.results.show();
  },

  onWindowResize: function onWindowResize() {
    if (this.results.is(':visible')) {
      this.equalizeResults();
    }
  },

  onFormSubmit: function onFormSubmit() {

    var country = this.country.val().toLowerCase();
    var countryName = this.country.find('option').filter(function () {
      return this.selected;
    }).text();
    var address = this.address.val();
    var query = address + ', ' + countryName;
    var position,
        radius = 10 * 1000;
    var addressInput = this.address;
    var retailer,
        found = 0,
        selectedCategory = this.category.val() + '';

    this.resultItems.empty();
    this.emptyMessage.hide();

    if (this.address.val().length > 2) {
      console.log('searching: ', query, ' - ', radius);

      this.geocoder.geocode({
        'address': query,
        'componentRestrictions': {
          'country': country
        }
      }, $.proxy(function (results, status) {

        if (status == google.maps.GeocoderStatus.OK) {

          // center
          var circle,
              bounds,
              userPosition,
              location = results[0].geometry.location;

          // set map center to user location
          if (this.center) this.center.setMap(null);
          this.center = new google.maps.Marker({
            //map: this.gmap,
            //clickable: false,
            position: location
          });
          userPosition = this.center.getPosition();

          for (var i = 0; i < this.markers.length; i++) {
            retailer = this.markers[i].retailer;

            circle = new google.maps.Circle({
              map: this.gmap,
              clickable: false,
              radius: retailer.radius * 1000,
              fillColor: '#fff',
              fillOpacity: 0,
              strokeColor: '#fff',
              strokeOpacity: 0
            });

            circle.bindTo('center', this.markers[i], 'position');
            bounds = circle.getBounds();

            if (bounds.contains(userPosition) && (!selectedCategory.length || retailer[selectedCategory] == 1)) {
              found++;
              this.markers[i].setMap(this.gmap);
              this.addResult(this.markers[i]);
            } else {
              this.markers[i].setMap(null);
              circle.setMap(null);
            }
          }

          // show result container
          this.results.show();

          // if map, center and circle
          if (this.gmap) {

            if (found) {
              this.equalizeResults();
            } else {
              // show "no results found"
              this.emptyMessage.show();
            }

            this.gmap.fitBounds(bounds);
            this.gmap.setCenter(location);
            this.gmap.setZoom(10);
            this.overlay.hide();
          }
        } else {
          console.log("Geocode was not successful for the following reason: " + status);
          addressInput.focus();
          $('#map_result_empty, #map_results').show();
        }
      }, this));
    } else {

      // without ZIPCODE
      for (var i = 0; i < this.markers.length; i++) {
        retailer = this.markers[i].retailer;
        if (retailer[selectedCategory] == 1) {
          found++;
          this.addResult(this.markers[i]);
        }
      }

      if (found) {
        this.equalizeResults();
      } else {
        // show "no results found"
        this.emptyMessage.show();
      }
    }

    return false;
  },

  centerAndPan: function centerAndPan(marker) {
    this.gmap.setCenterWithOffset(marker.getPosition(), 0, 0);
  },

  addResult: function addResult(marker) {
    var item = $(this.resultTpl);

    // logo
    if (marker.retailer.logo && marker.retailer.logo.length) {
      item.find('.bild').html('<img src="' + marker.retailer.logo + '" class="retailer-logo">');
    }
    item.find('.name').html(marker.retailer.name);
    item.find('.city').text(marker.retailer.city);
    item.find('.zip').text(marker.retailer.zip);

    if (marker.retailer.border && marker.retailer.border.length) {
      item.find('.item').css('border-color', marker.retailer.border);
    }
    if (marker.retailer.color && marker.retailer.color.length) {
      item.find('.item').css('background-color', marker.retailer.color);
    }

    if (marker.retailer.landingpage === 0) {
      item.find('.landingpage').hide();
    } else {
      item.find('.landingpage').show(0).css('display', 'inline-block');
    }
    item.find('a').on('click', $.proxy(function () {
      this.showMarkerOverlay(marker);
      $.smoothScroll({
        offset: -75,
        scrollTarget: $('#store')
      });
      return false;
    }, this));
    this.resultItems.append(item);
  },

  showMarkerOverlay: function showMarkerOverlay(marker) {
    // reset
    this.overlay.find('.data-field').empty();
    this.overlay.find('.link-phone').hide();
    this.overlay.find('.link-email').hide();

    // logo
    this.overlay.find('.logo').html('');
    if (marker.retailer.logo && marker.retailer.logo.length) {
      this.overlay.find('.logo').html('<img src="' + marker.retailer.logo + '" class="retailer-logo">');
    }

    // image
    this.overlay.find('.image').html('');
    var images = [];
    if (marker.retailer.image && marker.retailer.image.length) {
      images.push(marker.retailer.image);
    }
    if (marker.retailer.image2 && marker.retailer.image2.length) {
      images.push(marker.retailer.image2);
    }
    if (marker.retailer.image3 && marker.retailer.image3.length) {
      images.push(marker.retailer.image3);
    }

    if (images.length) {
      var carousel = '<div id="carousel" class="carousel slide" data-ride="carousel">';
      carousel += '<div class="carousel-inner" role="listbox">';

      carousel += '<ol class="carousel-indicators">';
      for (var y = 0; y < images.length; y++) {
        if (y === 0) {
          carousel += '<li data-target="#carousel" data-slide-to="0" class="active"></li>';
        } else {
          carousel += '<li data-target="#carousel" data-slide-to="' + y + '"></li>';
        }
      }
      carousel += '</ol>';

      for (var x = 0; x < images.length; x++) {
        if (x === 0) {
          carousel += '<div class="item active">';
          carousel += '<div class="embed-responsive embed-responsive-16by9" style="background-image:url(' + images[x] + ')"></div>';
          carousel += '</div>';
        } else {
          carousel += '<div class="item">';
          carousel += '<div class="embed-responsive embed-responsive-16by9" style="background-image:url(' + images[x] + ')"></div>';
          carousel += '</div>';
        }
      }
      carousel += '</div>';
      carousel += '</div>';

      this.overlay.find('.image').html(carousel);
      this.overlay.find('.image').find('.carousel').carousel();
    }

    // attributes
    this.overlay.find('.attrib').hide();

    if (marker.retailer.handel) {
      this.overlay.find('.attrib.handel').show();
    }
    if (marker.retailer.kirche) {
      this.overlay.find('.attrib.kirche').show();
    }
    if (marker.retailer.freizeit) {
      this.overlay.find('.attrib.freizeit').show();
    }
    if (marker.retailer.gastro) {
      this.overlay.find('.attrib.gastro').show();
    }
    if (marker.retailer.bildung) {
      this.overlay.find('.attrib.bildung').show();
    }
    if (marker.retailer.konferenz) {
      this.overlay.find('.attrib.konferrenz').show();
    }

    // text
    this.overlay.find('.description').html(marker.retailer.description);
    this.overlay.find('.name').html(marker.retailer.name);
    this.overlay.find('.street').html(marker.retailer.address);
    this.overlay.find('.zip').html(marker.retailer.zip);
    this.overlay.find('.city').html(marker.retailer.city);
    this.overlay.find('.country').html(marker.retailer.country);
    this.overlay.find('.zeiten').html(marker.retailer.openhours);
    this.overlay.find('.contact').html(marker.retailer.contact);
    this.overlay.find('.landingpage').attr('href', '#').hide();

    // buttons
    if (marker.retailer.phone && marker.retailer.phone.length) {
      this.overlay.find('.phone').html('T: ' + marker.retailer.phone);
    }
    if (marker.retailer.fax && marker.retailer.fax.length) {
      this.overlay.find('.fax').html('F: ' + marker.retailer.fax);
    }

    this.overlay.find('.link-email').hide();
    if (marker.retailer.email && marker.retailer.email.length) {
      var email = _retailerDecrypt(marker.retailer.email);
      this.overlay.find('.email').html(email);
      this.overlay.find('.link-email').attr('href', 'mailto:' + email).show();
    }

    this.overlay.find('.link-web').hide();
    if (marker.retailer.www && marker.retailer.www.length) {
      marker.retailer.www = marker.retailer.www.replace(' _blank', '');

      this.overlay.find('.web').html(marker.retailer.www);

      if (marker.retailer.www.indexOf('http') === -1) {
        marker.retailer.www = 'http://' + marker.retailer.www;
      }
      this.overlay.find('.link-web').attr('href', marker.retailer.www).show();
    }

    // remove all markers
    for (var x = 0; x < this.markers.length; x++) {
      this.markers[x].setMap(null);
    }

    // show and pan
    this.overlay.show();
    this.active = marker;
    marker.setMap(this.gmap);
    this.centerAndPan(marker);
  },

  equalizeResults: function equalizeResults() {
    $('.eq-element').height('').equalizeHeight();
  },

  /**
   * Add functionality to Map api
   */
  overloadGoogleMap: function overloadGoogleMap() {
    // add "setCenterWithOffset" method
    google.maps.Map.prototype.setCenterWithOffset = function (latlng, offsetX, offsetY) {
      var map = this;
      var ov = new google.maps.OverlayView();
      ov.onAdd = function () {
        var proj = this.getProjection();
        var aPoint = proj.fromLatLngToContainerPixel(latlng);
        aPoint.x = aPoint.x + offsetX;
        aPoint.y = aPoint.y + offsetY;
        map.setCenter(proj.fromContainerPixelToLatLng(aPoint));
      };
      ov.draw = function () {};
      ov.setMap(this);
    };
  }
};

module.exports = Retailer;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],31:[function(require,module,exports){
(function (global){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var Merkliste = {
  lsKey: 'inquiry_items',
  lsKeyUser: 'inquiry_user',
  modal: null,
  form: null,
  formIntro: null,
  toggle: null,
  products: null,
  orderTextarea: null,
  submitButton: null,
  emptyInfo: null,
  items: [],
  user: {},

  initialize: function initialize() {
    this.modal = $('#merkliste');
    this.form = $('#merkliste_form');
    this.formIntro = $('#merkliste_intro');
    this.emptyInfo = $('#merkliste_empty');
    this.toggle = $('#header').find('.toggle-favorites');
    this.rowTemplate = $('#merkliste_product').html();
    this.products = $('#merkliste_products');
    this.orderTextarea = $('#merkliste_order');
    this.productsList = this.products.find('.items');
    this.submitButton = this.form.find('.submit-form');

    this.storage = {
      // dummy
      get: function get(key, cb) {
        if (typeof cb === 'function') cb({});
      },
      set: function set(key, val, cb) {
        if (typeof cb === 'function') cb({});
      }
    };
    this.items = [];
    this.user = {};

    var form = this.modal.find('form');
    var infoThanks = $('#merkliste_submit_thanks');
    var infoEmpty = $('#merkliste_submit_empty');
    var favlist = this;

    var captchaInput = this.modal.find('.captcha-input');
    captchaInput.realperson({
      length: 6,
      regenerate: this.modal.find('.captcha-regen').data('value')
    });

    /**
     * --------------- Form Submit
     */

    this.modal.on('submit', 'form', function () {
      var url = '/?form=merkliste';

      // save user details
      favlist.form.find('input,textarea,select').each(function () {
        if ($(this).attr('name') !== 'message' && $(this).attr('name') !== 'order' && $(this).attr('name') !== 'merchant') {
          favlist.user[$(this).attr('name')] = $(this).val();
        }
      });
      favlist.storage.set(favlist.lsKeyUser, favlist.user);

      // validate
      if (favlist.items.length === 0) {
        // no items
        if ($(window).width() <= 768) {
          var message = $.trim(infoEmpty.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
          alert(message);
        } else {
          infoEmpty.show();
        }
        favlist.submitButton.removeAttr('disabled').prop('disabled', false);
        favlist.submitButton.text(favlist.submitButton.data('text-default'));
      } else if (!favlist.modal.find('.at-error').size()) {
        // no errors
        // all okay, send with AJAX
        $.post(url, form.serialize(), function (result) {
          if (result.success) {
            if ($(window).width() <= 768) {
              var message = $.trim(infoThanks.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
              alert(message);
            } else {
              infoThanks.show();
            }
            favlist.form.addClass('hidden');
            favlist.formIntro.removeClass('hidden');
          } else {
            alert(result.error);
          }
          favlist.submitButton.removeAttr('disabled').prop('disabled', false);
          favlist.submitButton.text(favlist.submitButton.data('text-default'));
        });
      }
      return false;
    });

    /**
     * --------------- Event Handlers
     */

    // page-wide hook for adding items to the list
    App.element.on('click', '.add-favorites', function (e) {
      favlist.addItem($(this).data());
      return false;
    });

    // show form on click and render
    this.modal.on('click', '.show-form', function () {
      favlist.form.removeClass('hidden');
      favlist.formIntro.addClass('hidden');
      favlist.renderOrder();
    });

    // hide form on close click
    this.modal.on('click', '.close-form', function () {
      favlist.form.addClass('hidden');
      favlist.formIntro.removeClass('hidden');
      $(this).parents('.modal').find('.modal-close').click();
    });

    // re-render order on submit click
    this.modal.on('click', '.submit-form', function () {
      favlist.renderOrder();
    });

    // click anywhere on message to hide
    this.modal.on('click', '.infomessage', function () {
      favlist.modal.find('.infomessage').hide();
    });

    // delete item from list
    this.productsList.on('click', '.remove-item', function () {
      var data = $(this).parents('.item').data('_data');
      favlist.removeItem(data);
    });

    // change amount
    this.productsList.on('change', 'input.product-amount', function () {
      var data = $(this).parents('.item').data('_data');
      favlist.updateItem(data, parseInt($(this).val(), 10));
    });

    // Storage is ready
    $(window).on('storage.monacor', function (e, StorageMonacor) {

      if ((typeof StorageMonacor === 'undefined' ? 'undefined' : _typeof(StorageMonacor)) !== 'object') {
        // fallback
        if ((typeof MonacorStorage === 'undefined' ? 'undefined' : _typeof(MonacorStorage)) === 'object') {
          StorageMonacor = MonacorStorage;
        }
      }

      if ((typeof StorageMonacor === 'undefined' ? 'undefined' : _typeof(StorageMonacor)) !== 'object') {
        // init error
        throw new Error('MonacorStorage was not an Object, was: ' + (typeof StorageMonacor === 'undefined' ? 'undefined' : _typeof(StorageMonacor)));
      } else {
        favlist.storage = StorageMonacor;

        // retrieve items
        favlist.storage.get(favlist.lsKey, function (data) {
          if (data.result) {
            favlist.items = data.result;
          }
          // render list
          favlist.render();
          favlist.renderOrder();
        });

        // retrieve user data
        favlist.storage.get(favlist.lsKeyUser, function (data) {
          if (data.result) {
            favlist.user = data.result;
          }
          // fill form
          $.each(favlist.user, function (name, val) {
            favlist.form.find('input[name="' + name + '"]').val(val);
            if (name === 'country') {
              var select = favlist.form.find('select[name="country"]');
              var options = select.get(0).options;
              for (var i = 0; i < options.length; i++) {
                if ($(options[i]).val() === val) {
                  select.get(0).selectedIndex = options[i].index;
                }
              }
            }
          });
        });
      }
    });
  },

  /**
   * --------------- List Storage API
   */

  hasItem: function hasItem(data) {
    var exists = false;
    $.each(this.items, function (i, stored) {
      if (stored.productId == data.productId) {
        exists = true;
      }
    });
    return exists;
  },

  updateItem: function updateItem(data, amount) {
    if (amount == 0) {
      this.removeItem(data);
      return true;
    }
    if (this.hasItem(data)) {
      var favlist = this,
          itemsNew = [];
      $.each(this.items, function (i, stored) {
        if (stored.productId == data.productId) {
          stored.amount = amount;
        }
        itemsNew.push(stored);
      });
      this.items = itemsNew; // replace
      this.storage.set(this.lsKey, itemsNew, function () {
        favlist.render();
        favlist.renderOrder();
      });
      return true;
    }
    return false;
  },

  addItem: function addItem(data) {
    var productUrl,
        favlist = this;
    if (!this.hasItem(data)) {
      productUrl = 'https://' + location.host + '/' + data.productUrl;
      data.productUrl = productUrl;
      data.amount = 1;
      this.items.push(data); // append
      this.storage.set(this.lsKey, this.items, function () {
        favlist.render();
        favlist.renderOrder();
      });
      return true;
    }
    return false;
  },

  removeItem: function removeItem(data) {
    var favlist = this,
        itemsNew = [];
    if (this.hasItem(data)) {
      $.each(this.items, function (i, stored) {
        if (stored.productId != data.productId) {
          itemsNew.push(stored);
        }
      });
      this.items = itemsNew; // replace
      this.storage.set(this.lsKey, itemsNew, function () {
        favlist.render();
        favlist.renderOrder();
      });
      return true;
    }
    return false;
  },

  /**
   * --------------- List Rendering
   */

  renderOrder: function renderOrder() {
    var ordertext = '';
    $.each(this.items, function () {
      ordertext += this.amount + 'x ' + this.productName + ' [' + this.productOrdernr + ']' + '\n';
    });
    this.orderTextarea.val(ordertext);
  },

  render: function render() {
    // render button badge
    if (this.items.length === 0) {
      this.toggle.addClass('favorites-empty');
      this.emptyInfo.removeClass('hidden');
      this.form.addClass('hidden');
      this.formIntro.addClass('hidden');
      this.products.addClass('hidden');
    } else {
      this.toggle.removeClass('favorites-empty');
      this.formIntro.removeClass('hidden');
      this.emptyInfo.addClass('hidden');
      this.products.removeClass('hidden');
    }
    this.toggle.find('.badge').text('' + this.items.length);

    // render products
    this.productsList.empty();
    var rowTemplate = this.rowTemplate;
    var container = this.productsList;
    $.each(this.items, function (i, row) {
      var template = $(rowTemplate);

      // corner badges
      //if (row.productSale) {
      //  template.find('.corner-sale').show();
      //}
      //if (row.productNew) {
      //  template.find('.corner-new').show();
      //}

      // item class
      if (row.productBrand) {
        template.addClass('item-' + row.productBrand);
      } else {
        // legacy detection
        if (row.productUrl.indexOf('/monacor/') !== -1) {
          template.addClass('item-monacor');
        } else if (row.productUrl.indexOf('/img/') !== -1) {
          template.addClass('item-img');
        } else if (row.productUrl.indexOf('/jts/') !== -1) {
          template.addClass('item-jts');
        }
      }

      // item id
      template.attr('id', 'prod_' + row.productId);

      // content
      template.find('a.product-name').attr('href', row.productUrl);
      template.find('.product-name').text(row.productName);
      template.find('.product-desc').html(row.productDesc);
      template.find('.product-ordernr').text(row.productOrdernr);
      template.find('.product-image').attr('src', row.productImage);
      template.find('.product-amount').val(row.amount);
      template.data('_data', row);

      // add to list
      container.append(template);
    });
  }
};

module.exports = Merkliste;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],32:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var PrintOrder = {
  form: null,
  submit: null,

  initialize: function initialize() {
    this.form = $('.printorder-form');
    if (this.form.size()) {
      this.printorder = this.form.parents('.printorder');
      this.submit = this.form.find('button[type=submit]');

      var imageWrap = this.printorder.find('.printorder-image');
      this.printorder.find('.printorder-catalogs').on('mouseenter', 'li', function () {
        var checkbox = $(this).find('input');
        imageWrap.find('img').addClass('hidden');
        $(checkbox.data('image')).removeClass('hidden');
      });

      this.form.on('submit', $.proxy(function () {
        if (this.unlocked && !this.form.find('.at-error').size()) {
          var url = this.form.attr('action');
          $.post(url, this.form.serialize(), $.proxy(function (res) {
            if ($(window).width() <= 768) {
              var message = $.trim(thanks.find('.infomessage-overlay').first().text()).replace(/ \s+ /g, '');
              alert(message);
            } else {
              thanks.show();
            }
          }, this));
        }
        return false;
      }, this));

      var thanks = $('#printorder_thanks');
      thanks.on('click', $.proxy(function () {
        this.form.find('input[type=email]').val('');
        this.form.find('input[type=text]').val('');
        this.submit.removeAttr('disabled');
        this.submit.text(this.submit.data('default'));
        thanks.hide();
      }, this));

      var printorderOuter = this.printorder.find('.printorder-outer');
      this.printorder.find('.printorder-catalogs input').on('click', function () {
        if ($(this).hasClass('offer-download')) {
          printorderOuter.fadeIn('fast');
        } else {
          printorderOuter.fadeOut('fast');
        }
      });

      // captcha lock
      this.unlocked = false;
      this.submit.attr('disabled', 'disabled').prop('disabled', true);
      window.printorderUnlock = $.proxy(function () {
        this.submit.removeAttr('disabled').prop('disabled', false);
        this.unlocked = true;
      }, this);
    }
  }
};

module.exports = PrintOrder;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],33:[function(require,module,exports){
(function (global){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

window._retailerDecrypt = function (DataEncrypt) {
  var DataKey = CryptoJS.enc.Utf8.parse(_retailers_k);
  var DataVector = CryptoJS.enc.Utf8.parse(_retailers_v);
  var Data = CryptoJS.AES.decrypt(DataEncrypt, DataKey, { iv: DataVector });
  if ((typeof Data === 'undefined' ? 'undefined' : _typeof(Data)) == 'object') {
    return Data.toString(CryptoJS.enc.Utf8);
  }
  return '';
};

var Retailer = {
  // json
  data: null,

  // objects
  gmap: null,
  geocoder: null,
  center: null,
  circle: null,
  active: null,

  // elements
  map: null,
  form: null,
  country: null,
  address: null,
  fillcolor: null,
  results: null,
  overlay: null,
  resultItems: null,

  // templates
  resultTpl: '',

  // search
  markers: [],
  kmRadius: null,

  /**
   * Start
   */
  initialize: function initialize() {

    if (!$('#haendlersuche.haendler').size()) {
      return false;
    }

    this.form = $('#map_form');
    if (!this.form.size()) {
      return false;
    }

    if (typeof google === "undefined") {
      throw Error('Required google global not loaded. Is maps api loaded?');
    }
    if (typeof window._retailers === 'undefined') {
      throw Error('Required map data not found in source.');
    }
    this.overloadGoogleMap();

    this.data = window._retailers;

    this.map = $('#map');
    this.country = $('#map_country');
    this.kmRadius = $('#map_radius');
    this.emptyMessage = $('#map_result_empty');
    this.category = $('#map_productcategory');
    this.address = $('#map_address');
    this.fillcolor = $('#map_color');
    this.overlay = $('#map_overlay');
    this.gewerbe = $('#map_gewerbe');
    this.results = $('#map_results');
    this.resultItems = $('#map_result_items');
    this.resultTpl = $('#map_result_tpl').html();

    this.generateMarkers();
    this.geocoder = new google.maps.Geocoder();

    if (this.map.length) {
      this.gmap = new google.maps.Map(this.map.get(0), {
        center: { lat: 51.165691, lng: 10.451526000000058 },
        zoom: 6,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scaleControl: true
      });
    } else {
      this.gmap = null;
    }

    // listen
    this.form.on('submit', $.proxy(this.onFormSubmit, this));
    $(window).on('resize', $.proxy(this.onWindowResize, this));

    // map overlay actions, unless map is disabled
    if (this.overlay.length) {
      this.overlay.find('.actions .btn').on('click', $.proxy(function () {
        var address = '';
        this.overlay.find('address .data-field').each(function () {
          if ($(this).hasClass('country')) {
            // we only have GERMANY for now..
            // remove check if we expand to other countries and this info becomes relevant.
            address += '\n'; // extra linebreak after country, intro to contact.
          } else {
            address += $(this).text() + ($(this).hasClass('zip') ? ' ' : '\n');
          }
        });
        $('#merkliste_merch').val(address);
        $('#header').find('.toggle-favorites').first().click();
        $('#merkliste').find('.show-form').first().click();
      }, this));
    }

    return this;
  },

  generateMarkers: function generateMarkers() {
    var _self = this;
    this.markers = [];
    var retailer, latLng, marker;
    var image = {
      size: new google.maps.Size(44, 52),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(22, 52),
      url: '/design/img/stageline/map-pin-grey.png'
    };
    for (var i = 0; i < this.data.length; i++) {
      retailer = this.data[i];
      latLng = new google.maps.LatLng(retailer.lat, retailer.lng);
      marker = new google.maps.Marker({
        position: latLng,
        //icon: image,
        //shape: {
        //  coords: [22, 26, 25],
        //  type: 'circle'
        //},
        draggable: false,
        raiseOnDrage: false
      });
      marker.retailer = retailer;
      //console.log(latLng.lat(), latLng.lng(), 'data:', retailer);
      marker.addListener('click', $.proxy(function () {
        _self.showMarkerOverlay(this);
      }, marker));
      this.markers.push(marker);
    }
  },

  onWindowResize: function onWindowResize() {
    if (this.results.is(':visible')) {
      this.equalizeResults();
    }
    if (this.active) {
      this.centerAndPan(this.active);
    }
  },

  onFormSubmit: function onFormSubmit() {
    if (this.address.val().length < 2) {
      this.address.focus();
      return false;
    }
    var country = this.country.val().toLowerCase();
    var countryName = this.country.find('option').filter(function () {
      return this.selected;
    }).text();
    var address = this.address.val();
    var query = address + ', ' + countryName;
    var position,
        radius = this.kmRadius.val() * 1000;
    var addressInput = this.address;

    this.geocoder.geocode({
      'address': query,
      'componentRestrictions': {
        'country': country
      }
    }, $.proxy(function (results, status) {

      if (status == google.maps.GeocoderStatus.OK) {
        var previous,
            location = results[0].geometry.location;

        if (this.center) {
          previous = this.center.getPosition();
        }
        if (this.center) this.center.setMap(null);
        this.center = new google.maps.Marker({
          position: location
        });
        position = this.center.getPosition();

        if (this.circle) this.circle.setMap(null);

        this.resultItems.empty();
        this.emptyMessage.hide();

        var found = 0,
            bounds = new google.maps.LatLngBounds();

        var selected_schwerpunkt = this.category.val();
        var selected_gewerbe = this.gewerbe.val();

        for (var i = 0; i < this.markers.length; i++) {

          var schwerpunkte = this.markers[i].retailer.angebotsschwerpunkt.split(',');
          var schwerpunkt_match = $.inArray(selected_schwerpunkt, schwerpunkte) !== -1;

          var gewerberart = this.markers[i].retailer.gewerberart.split(',');
          var gewerberart_match = $.inArray(selected_gewerbe, gewerberart) !== -1;

          var param_match = schwerpunkt_match || gewerberart_match;
          var no_params = selected_schwerpunkt.length === 0 && selected_gewerbe.length === 0;

          if (this.category.val() && this.gewerbe.val()) {
            param_match = schwerpunkt_match && gewerberart_match;
          }

          if (google.maps.geometry.spherical.computeDistanceBetween(this.markers[i].getPosition(), position) < radius && (param_match || no_params)) {

            //console.log('param match', param_match, 'no params:', no_params);

            found++;
            bounds.extend(this.markers[i].getPosition());
            this.markers[i].labelContent = '<div class="n">' + found + "</div>";
            this.markers[i].setMap(this.gmap);
            this.addResult(this.markers[i]);
          } else {
            this.markers[i].setMap(null);
          }
        }

        // show result container
        this.results.show();

        // if map, center and circle
        if (this.gmap) {

          if (found) {
            // add circle
            this.circle = new google.maps.Circle({
              center: position,
              radius: radius,
              strokeWidth: 1,
              strokeColor: this.fillcolor.val(),
              fillOpacity: 0.35,
              fillColor: this.fillcolor.val(),
              map: this.gmap
            });
            this.equalizeResults();
          } else {
            // show "no results found"
            this.emptyMessage.show();
          }

          this.gmap.fitBounds(bounds);
          this.gmap.setCenter(location);
          this.gmap.setZoom(10);
          this.overlay.hide();
        }
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
        addressInput.focus();
        $('#map_result_empty, #map_results').show();
      }
    }, this));
    return false;
  },

  centerAndPan: function centerAndPan(marker) {
    if (marker && this.gmap) {
      if (App.body.width() < 753) {
        this.gmap.setCenterWithOffset(marker.getPosition(), 0, 0);
      } else {
        this.gmap.setCenterWithOffset(marker.getPosition(), 250, 0);
      }
    }
  },

  addResult: function addResult(marker) {
    var item = $(this.resultTpl);
    item.find('.name').html(marker.retailer.name);
    item.find('.city').text(marker.retailer.city);
    item.find('.zip').text(marker.retailer.zip);
    if (marker.retailer.landingpage === 0) {
      item.find('.landingpage').hide();
    } else {
      item.find('.landingpage').show(0).css('display', 'inline-block');
    }
    item.find('a').on('click', $.proxy(function () {
      this.showMarkerOverlay(marker);
      return false;
    }, this));
    this.resultItems.append(item);
  },

  showMarkerOverlay: function showMarkerOverlay(marker) {
    // reset
    this.overlay.find('.data-field').empty();
    this.overlay.find('.link-phone').hide();
    this.overlay.find('.link-email').hide();

    // text
    this.overlay.find('.name').html(marker.retailer.name);
    this.overlay.find('.street').html(marker.retailer.address);
    this.overlay.find('.zip').html(marker.retailer.zip);
    this.overlay.find('.city').html(marker.retailer.city);
    this.overlay.find('.country').html(marker.retailer.country);
    if (typeof marker.retailer.zeiten == "string") {
      this.overlay.find('.zeiten').html(marker.retailer.zeiten.replace(/\n/g, '<br>'));
    } else {
      this.overlay.find('.zeiten').html('');
    }
    this.overlay.find('.contact').html(marker.retailer.contact);

    this.overlay.find('.fax').html(marker.retailer.fax ? 'Fax: ' + marker.retailer.fax : '');

    // buttons
    if (marker.retailer.phone && marker.retailer.phone.length) {
      this.overlay.find('.phone').html('Tel: ' + marker.retailer.phone);
      this.overlay.find('.link-phone').attr('href', 'tel://' + marker.retailer.phone);
      this.overlay.find('.link-phone').show();
    }
    if (marker.retailer.email && marker.retailer.email.length) {
      var email = _retailerDecrypt(marker.retailer.email);
      this.overlay.find('.email').html(email);
      this.overlay.find('.link-email').attr('href', 'mailto:' + email);
      this.overlay.find('.link-email').show();
    }
    if (marker.retailer.landingpage) {
      if (marker.retailer.landingpage.detailLink) {
        this.overlay.find('.landingpage').attr('href', marker.retailer.landingpage.detailLink).show();
      } else {
        this.overlay.find('.landingpage').attr('href', '#').hide();
      }
      var teaserwrap = this.overlay.find('.productteaser');
      teaserwrap.html('');
      if (marker.retailer.landingpage._products && marker.retailer.landingpage._products.length > 0) {
        var template = $('#productteaser').html();
        $.each(marker.retailer.landingpage._products, function (index, product) {
          var prod = $(template);
          prod.find('.title').html(product.name);
          prod.find('a.item').attr('href', product.url);
          prod.find('.orderno').html(product.orderno);
          prod.find('.teaser').html(product.text);
          var price = product.price; // TODO: numberformat
          prod.find('.price span[itemprop="price"]').html(price).attr('content', price);
          teaserwrap.append(prod);
        });
      }
    } else {
      this.overlay.find('.landingpage').attr('href', '#').hide();
    }

    // show and pan
    this.overlay.show();
    this.active = marker;
    this.centerAndPan(marker);
  },

  equalizeResults: function equalizeResults() {
    $('.eq-element').height('').equalizeHeight();
  },

  /**
   * Add functionality to Map api
   */
  overloadGoogleMap: function overloadGoogleMap() {
    // add "setCenterWithOffset" method
    google.maps.Map.prototype.setCenterWithOffset = function (latlng, offsetX, offsetY) {
      var map = this;
      var ov = new google.maps.OverlayView();
      ov.onAdd = function () {
        var proj = this.getProjection();
        var aPoint = proj.fromLatLngToContainerPixel(latlng);
        aPoint.x = aPoint.x + offsetX;
        aPoint.y = aPoint.y + offsetY;
        map.setCenter(proj.fromContainerPixelToLatLng(aPoint));
      };
      ov.draw = function () {};
      ov.setMap(this);
    };
  }
};

module.exports = Retailer;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],34:[function(require,module,exports){
(function (global){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var App = require('../index.js');

var Searchbar = {
  el: null,
  form: null,
  input: null,
  currentValue: '',
  xhrCats: null,
  xhrProds: null,
  xhrPages: null,
  cache: {},
  baseurl: '',

  initialize: function initialize() {
    this.el = $('#searchbar');
    this.header = $('#header');
    this.form = this.el.find('form');
    this.input = this.form.find('input[name="s"]');
    this.toggle = this.header.find('.toggle-search');
    this.navLi = this.header.find('li.search');
    this.body = $('body');

    this.baseurl = this.form.attr('action');
    this.dropdown = $('#searchbar_results');

    this.products = $('#searchbar_products');
    this.productsList = this.products.find('ul');

    this.categories = $('#searchbar_categories');
    this.categoriesList = this.categories.find('ul');

    this.pages = $('#searchbar_pages');
    this.pagesList = this.pages.find('ul');

    var timeout = null,
        typingDelay = 350;

    this.input.attr('autocomplete', 'off');
    this.input.on('keyup', $.proxy(function (e) {
      if (e.which !== 0 && !(e.ctrlKey || e.metaKey || e.altKey)) {
        window.clearTimeout(timeout);
        timeout = window.setTimeout($.proxy(this.handleInput, this), typingDelay);
      }
    }, this));

    this.body.on('click', $.proxy(function () {
      this.dropdown.fadeOut(300);
    }, this));

    this.toggle.on('click', $.proxy(this.toggleSearch, this));
  },
  isValidQuery: function isValidQuery(value) {
    return value.length >= 2;
  },
  handleInput: function handleInput() {
    var value = this.getValue();
    if (this.isValidQuery(value)) {
      this.executeSearch(value);
    } else {
      this.dropdown.hide();
    }
  },
  hasCache: function hasCache(value, type) {
    if (!value.length) return false;
    if (type) {
      return typeof this.cache[value] !== 'undefined' && _typeof(this.cache[value][type]) === 'object';
    }
    return typeof this.cache[value] !== 'undefined';
  },
  getCache: function getCache(value, type) {
    if (!value.length) return false;
    if (type) {
      return this.cache[value][type];
    }
    return this.cache[value];
  },
  getValue: function getValue() {
    return $.trim(this.input.val());
  },
  executeSearch: function executeSearch(value) {
    if (!value) value = this.getValue();
    this.currentValue = value; // remember what is currently inside the input

    if ($('.layout-2021').length > 0) {
      if (this.hasCache(value, 'pages')) {
        this.renderPages(this.getCache(value, 'pages'));
      } else {
        this.queryPages(value);
      }
    }

    if (this.hasCache(value, 'products')) {
      this.renderProducts(this.getCache(value, 'products'));
    } else {
      this.queryProducts(value);
    }

    if (this.hasCache(value, 'categories')) {
      this.renderCategories(this.getCache(value, 'categories'));
    } else {
      this.queryCategories(value);
    }
  },
  queryPages: function queryPages(value) {
    if (this.xhrPages && this.xhrPages.readyState != 4) {
      this.xhrPages.abort();
    }
    this.xhrPages = $.getJSON(this.baseurl + '?t=pages&l=5&r=json&no_cache=1&s=' + encodeURIComponent(value), $.proxy(function (res) {
      if (!this.cache[value]) this.cache[value] = {}; // init value cache entry if missing
      this.cache[value]['pages'] = res.results; // add result to memory value cache
      if (res.params.search == this.currentValue) {
        // only render if result query matches current value.
        this.renderPages(res.results); // render PRODUCT data in dropdown
      }
    }, this));
  },
  queryProducts: function queryProducts(value) {
    if (this.xhrProds && this.xhrProds.readyState != 4) {
      this.xhrProds.abort();
    }
    this.xhrProds = $.getJSON(this.baseurl + '?t=products&l=5&r=json&no_cache=1&s=' + encodeURIComponent(value), $.proxy(function (res) {
      if (!this.cache[value]) this.cache[value] = {}; // init value cache entry if missing
      this.cache[value]['products'] = res.results.data; // add result to memory value cache
      if (res.params.search == this.currentValue) {
        // only render if result query matches current value.
        this.renderProducts(res.results.data); // render PRODUCT data in dropdown
      }
    }, this));
  },
  queryCategories: function queryCategories(value) {
    if (this.xhrCats && this.xhrCats.readyState != 4) {
      this.xhrCats.abort();
    }
    this.xhrCats = $.getJSON(this.baseurl + '?t=categories&l=5&r=json&no_cache=1&s=' + encodeURIComponent(value), $.proxy(function (res) {
      if (!this.cache[value]) this.cache[value] = {}; // init value cache entry if missing
      this.cache[value]['categories'] = res.results.data; // add result to memory value cache
      if (res.params.search == this.currentValue) {
        // only render if result query matches current value.
        this.renderCategories(res.results.data); // render CATEGORY data in dropdown
      }
    }, this));
  },
  renderPages: function renderPages(response) {
    this.pagesList.empty();
    if (!response || response.length === 0 || response.total === 0) {
      this.pages.hide();
    } else {
      $.each(response, $.proxy(function (i, result) {
        this.pagesList.append('<li class="item"><a href="' + result.url + '">' + result.title + '</a></li>');
      }, this));
      this.dropdown.fadeIn(300);
      this.pages.show();
    }
  },
  renderProducts: function renderProducts(response) {
    this.productsList.empty();
    if (!response || response.length === 0) {
      this.products.hide();
    } else {
      $.each(response, $.proxy(function (i, result) {
        var url = (this.baseurl + result.url).replace('//', '/');
        this.productsList.append('<li class="item"><div class="thumbnail"><img src="' + result.image.pathSmall + '"></div><a href="' + url + '">' + result.artnr + '</a><br>' + result.meta.teaser + '</li>');
      }, this));
      this.dropdown.fadeIn(300);
      this.products.show();
    }
  },
  renderCategories: function renderCategories(response) {
    this.categoriesList.empty();
    if (!response || response.length === 0) {
      this.categories.hide();
    } else {
      $.each(response, $.proxy(function (i, result) {
        var url = (this.baseurl + result.url).replace('//', '/');
        this.categoriesList.append('<li class="item"><a href="' + url + '">' + result.name + '</a></li>');
      }, this));
      this.dropdown.fadeIn(300);
      this.categories.show();
    }
  },
  toggleSearch: function toggleSearch() {
    if (this.el.is(':visible')) {
      this.el.fadeOut(250);
      this.navLi.removeClass('active');
      this.body.removeClass('search-visible');
    } else {
      var searchinput = this.input;
      this.el.fadeIn(250, function () {
        searchinput.focus();
      });
      this.navLi.addClass('active');
      this.body.addClass('search-visible');
    }
    return false;
  }
};

module.exports = Searchbar;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"../index.js":22}],35:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

// Equal Height Script
$.fn.equalizeHeight = function () {
  var maxHeight = 0,
      itemHeight;
  for (var i = 0; i < this.length; i++) {
    itemHeight = $(this[i]).css('height', 'auto').height();
    if (maxHeight < itemHeight) {
      maxHeight = itemHeight;
    }
  }
  return $(this).height(maxHeight);
};

$(window).on('load resize', function () {
  $('.eq-elements').each(function () {
    $(this).find('.eq-element').equalizeHeight();
  });
});

$(window).on('resize', function () {
  var header = $('#header');
  header.find(".navbar-collapse").css({
    maxHeight: $(window).height() - header.find(".navbar-header").height() + "px"
  });
});

$(window).ajaxStop(function () {
  $('.eq-elements').each(function () {
    $(this).find('.eq-element').equalizeHeight();
  });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],36:[function(require,module,exports){
(function (global){
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
$(function () {

  $(document).on('click', 'a', function () {
    var href = $(this).attr('href') + '',
        message = '';

    // not empty, starts with http and GA is available
    if (href.length && href.match('^http') && typeof ga !== 'undefined') {

      // does not contain our own hostname
      if (href.indexOf(location.hostname) === -1) {
        message = 'Quelle: ' + location.href + ' Ziel: ' + href;
        ga('send', 'event', 'Links', 'Externen Link angeklickt', message);
      }
    }
  });
});

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],37:[function(require,module,exports){
'use strict';

var App = require('./app');
var $win = $(window);
var $doc = $(document);

$doc.ready(function () {
  // slider home
  var $sliderHomeSlides = $('.slider-home .slides');
  var $sliderHomePaging = $('.slider-home .slider-paging');

  $sliderHomeSlides.slick({
    fade: true,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000
  });

  $sliderHomeSlides.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $sliderHomePaging.find('li').eq(nextSlide).addClass('current').siblings().removeClass('current');
  });

  $sliderHomePaging.find('a').on('click', function (event) {
    event.preventDefault();

    var idx = $(this).closest('li').index();

    $sliderHomeSlides.slick('slickGoTo', idx);
  });

  $('.sroll-top').on('click', function (event) {
    event.preventDefault();

    $('html, body').animate({
      scrollTop: 0
    }, 400);
  });

  $('.support h2').on('click', function () {
    if ($win.width() < 768) {
      $(this).closest('.support').toggleClass('expanded');
    }
  });
});

$win.on('load resize', function () {
  if ($win.width() > 767) {
    $('.service-content').height('').equalizeHeight();
  }
});

},{"./app":22}]},{},[37]);
